<template>
    <div class="PageSitemap PageBasic boxed padded">
        <h1>Sitemap</h1>
        <a v-for="e in entries" :key="e.url"
            :href="e.url"
            :aria-label="`Ga naar ${title(e).replace(' > ', ', categorie')}`">
            {{title(e)}}
        </a>
    </div>
</template>
<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import Speech from './Speech';

const field = (label,name,type) => ({ label, name, type });
const axios = require('axios');
import slug from './slug.js';
import translateError from './translate-error';

const entry = (slug, titleKey) => {
    return ({url:'/#'+slug, titleKey});
}
const titled = (slug, title) => {
    return ({url:'/#'+slug, title});
}
export default {
    async mounted() {
        const pages = (await axios.get('/pages')).data;
        this.titles = Object.fromEntries(pages.map(p => {
            return [
                p.slug,
                p.title,
            ]
        }));
},

    data() {
        return {
            titles: {
            },
        };
    },
    methods: {
        title(e) {
            return e.titleKey ? this.titles[e.titleKey] : e.title;
        },
    },
    computed: {
        entries() {
            const arr = [
                entry('/voor-organisaties', 'organisations'),
                entry('/voor-organisaties/over', 'organisations-about'),
                entry('/voor-organisaties/extra', 'organisations-extra'),
                entry('/voor-organisaties/pro', 'organisations-pro'),
                entry('/vraag-en-antwoord', 'faq'),
                entry('/mijn-resultaten', 'mijn-resultaten'),
                entry('/contact', 'contact'),
                entry('/privacy', 'privacy'),
                entry('/register', 'register'),
                entry('/login', 'login'),
                entry('/profile', 'profile'),
                entry('/toegankelijkheidsverklaring', 'toegankelijkheidsverklaring'),
                titled('/programmas', `Programma's`),
            ];
            
            const recurse = (n, chain) => {
                if(n.intro) {
                    const all = [...chain, n];
                    const full = '/programmas/' + all.map(n => slug(n.name)).join('/');
                    const title = all.slice(0).map(n => n.name).join(' > ');
                    arr.push( titled(full, title));
                } else if(n.childs) {
                    n.childs.map(c => recurse(c, [...chain, n]));
                }
            }
            if(this.programRoot) {
                this.programRoot.childs.forEach(n => {
                    arr.push( titled('/programmas/'+slug(n.name), `Programma's > ${n.name}`) );
                    /* recurse(n,[]); */
                });
            }
            return arr;
        },
        ...mapGetters([
            'programRoot',
        ])
    }
};

</script>
<style lang="scss">

.PageSitemap {
    h1 {
        margin-bottom: 20px;
    }

    a {
    display: block;
    padding-bottom: 10px;
    }

}

</style>
