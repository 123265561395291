<template>
    <popover class="Forgot">
        <h1>Wachtwoord vergeten</h1>
        <template v-if="!complete">
            <p>Vul je e-mailadres in en we sturen je een instructie om je wachtwoord opnieuw in te stellen</p>
            <form @submit.prevent="resetPassword">
                <div class="row">
                    <label for="email">E-mailadres</label>
                    <input autocomplete="email" id="email" v-model="email" />
                </div>
                <div v-if="error" class="row error">{{error}}</div>
                <div class="row">
                    <button aria-label="Verstuur e-mail">Verstuur e-mail</button>
                </div>
            </form>
        </template>
        <template v-else>
            <p>Er is een e-mail verstuurd met instructies om je wachtwoord te wijzigen.</p>
        </template>
    </popover>
</template>
<script>

import Popover from './Popover';
import { mapGetters } from 'vuex';
import axios from 'axios';
import translateError from './translate-error';

export default {
    data() {
        return {
            error: null,
            email: null,
            complete: false,
        };
    },
    components: {
        Popover,
    },
    computed: {
        forgotLink() {
            return this.hash.replace(/login$/, 'forgot');
            
        },
        ...mapGetters([
            'hash',
        ]),
    },

    methods: {
        resetPassword() {
            axios.post('/users/forgot', { email:this.email }).then( ()=> {
                this.complete = true;
            }).catch( (e) => {
                this.error = translateError(e.response.data);
            });
        },
    }
};

</script>

<style lang="scss">

@import "./colors.scss";

.Forgot {
}

</style>

