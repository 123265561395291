<template>
    <div class="Languages" aria-role="listbox">
        <div v-for="lang in langs" :key="lang.code"
            aria-role="listitem"
            :aria-current="active == lang"
            :aria-label="lang.code"
             @click="langClick(lang)" :class="{ active:active == lang }"
             >
             <img :src="icons[lang.code]" :alt="flagAlt(lang)" />
             {{lang.name}}
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import langs from './langs';


export default {
    created() {
        this.active = this.langs.find(l => l.code === this.language);
    },

    watch: {
        language(nv) {
            this.active = this.langs.find(l => l.code === this.language);
        },
    },

    computed: {
        ...mapGetters([
            'language',
        ]),
    },
    data() {
        return {
            active: null,
            langs,
            icons: {
                nl: require('flag-icon-css/flags/4x3/nl.svg'),
                en: require('flag-icon-css/flags/4x3/gb.svg'),
                ar: require('./ar.svg'),
            },
        };
    },
    methods: {
        flagAlt(lang) {
            return {
                nl: 'Dutch flag',
                en: 'English flag',
                ar: 'Arabic flag',
            }[lang.code];
        },
        langClick(lang) {
            this.active = lang;
            this.$emit('change', this.active);
        }
    }
}

</script>
<style lang="scss">

@import "./colors.scss";
@import "./variables.scss";

.Languages {
    margin-top: 20px;
    img {
        width: 100px;
        display: block;
        margin-bottom: 4px;
        cursor: pointer;
        box-sizing: border-box;
    }
    .active img {
        border: 4px solid $purple;
    }

    div {
        display: inline-block;
        text-align: center;
        margin-right: 80px;
    }
    div:last-child {
        margin-right: 0px;
    }
    @media (max-width: $phone) {
        div {
            margin-right: 40px;
        }
    }

    &.small {
        margin-bottom: -20px;
        img {
            width: 70px;
            transform: scale(1,0.7);
            margin-bottom: -8px;
        }

        div {
            margin-right: 20px;
            font-size: 12px;
        }
    }
}

</style>
