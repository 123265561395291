import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import slug from './slug';
import langs from './langs';

import stats from './stats';
import courseProgressInfo from './course-progress-info';

import openScorm from './open-scorm';
import translateError from './translate-error';
import { EventBus } from './event-bus.js';

Vue.use(Vuex)

function findProgram(node, parts) {
    if(!node) { return null; }
    for(let i=1; i<parts.length; i++) {
        const name = parts[i].replace('course-','');
        if(Array.isArray(node.childs)) {
            const found = node.childs.find( c => slug(c.name) === name );
            if(found) {
                node = found;
            }
        }
    }
    return node;
}

let lazyUpdateUserId = 0;
const saveUser = (user) => {
    if(!user) {
        sessionStorage.removeItem('user');
        return;
    }
    sessionStorage.setItem('user', JSON.stringify(user));
    clearTimeout(lazyUpdateUserId);
    lazyUpdateUserId = setTimeout( ()=> {
        axios.patch('/users/'+user._id, user).then( () => {
            console.log('updated');
        });
    },1000);
}

const checkInput = (input, fields) => {
    const missing = [];
    fields.forEach( f => {
        if(!input[f.name]) {
            missing.push(f.label);
        }
    });

    let error = '';
    if(missing.length) {
        error += `${missing.join(',')} ${missing.length == 1 ? 'is verplicht' : 'zijn verplichte velden'}.\n`;
    }
    if(!input.agreedTerms) {
        error += 'Je hebt nog geen akkoord gegeven op de voorwaarden.';
    }
    if(error.length > 0) {
        throw Error(error);
    }

}


const store = new Vuex.Store({
	state: {
		hash:null,
		home:null,
		registered:false,
		programRoot:null,
        modulesMap: null,
        content:{},
        user: null,
        phoneView: false,
        width: 0,
		loginError:null,
		registerError:null,
        popover: null,
        popovers: {
            login: 'Login',
            profile: 'Profile',
            forgot: 'Forgot',
            register: 'Register',
            'instruction-video': 'InstructionVideo',
            InstructionVideo: 'instruction-video',
        },
        chosenLanguage: null,
        oefenen: null,
        connect: null,
	},

	mutations: {
        setOefenen(s, payload) {
            s.oefenen = payload;
            sessionStorage.setItem('oefenen', payload);
        },
        setConnect(s, payload) {
            s.connect = payload;
            stats.source = payload.client;
            sessionStorage.setItem('connect', payload);
        },
        setChosenLanguage(s, code) {
            s.chosenLanguage = code;
        },
        setPhoneView(s, payload) {
            s.phoneView = payload;
        },

        setWidth(s, payload) {
            s.width = payload;
        },
        setPopover(s, payload) {
            s.popover = payload;
            if(!payload) {
                EventBus.$emit('popover-close');
            } else {
                window.scrollTo(0,0);
            }
        },

        setLoginError(s, payload) {
            s.loginError = payload;
        },
        setRegisterError(s, payload) {
            s.registerError = payload;
        },
        setRegistered(s, payload) {
            s.registered = payload;
        },


        updateUser(s, payload) {
            if(payload === null) {
                s.user = null;
            } else {
                if(!s.user) {
                    stats.setLoggedIn(true);
                    s.user = payload;
                } else {
                    Object.assign(s.user, payload);
                }
            }
            saveUser(s.user);
        },

        updateCourseStatus(s, { update, course }) {
            let user = s.user;
            if(user === undefined) { return; }

            if(user.progress === undefined) {
                Vue.set(user, 'progress', {});
            }

            let { id } = course;
            let progress = user.progress[ id ];
            if(!progress) {
                Vue.set(user.progress, id, update);
            } else {
                try {
                    let op = user.progress[course.scorm.file];
                    if(op !== undefined) {
                        Object.assign( progress, op );
                    }
                } catch(e) {
                }
                Object.assign( progress, update );
            }
            saveUser(user);
        },

        setContent(s, { path, content }) {
            Vue.set(s.content, path, content);
        },

		setHash(s, payload) {
            s.hash = payload;
		},

		setHome(s, payload) {
			s.home = payload;
		},

		setProgramRoot(s, payload) {
			s.programRoot = payload;
		},
		setModulesMap(s, payload) {
			s.modulesMap = payload;
		},
	},

	actions: {
        setLastCourse(s, payload) {
            if(s.getters.user && s.getters.user.email) {
                s.commit('updateUser', { lastCourse:payload.id });
            }
        },
        setLanguage(s, code) {
            s.commit('setChosenLanguage', code);
            if(s.getters.user && s.getters.user.email) {
                s.commit('updateUser', { language:code });
            }
        },

        startCourse(s, course) {
            const { oefenen, connect, user } = s.getters;
            let allowExternal = oefenen || connect || user;
            const isFestival = location.hash.match(/festival-sterk-aan-het-werk$/) !== null;
            if(!allowExternal && !isFestival) {
                s.commit('setPopover', 'Login');
                EventBus.$once('popover-close', ()=> {
                    if(!s.user) {
                        openScorm(course);
                    }
                });
            } else {
                openScorm(course);
            }
        },

        continueCourse(s) {
            const { user } = s.getters;
            const { lastCourse } = user;
            if(lastCourse) {
                let progress = user.progress[ lastCourse ];
                if(progress && courseProgressInfo(progress) < 2) {
                    s.commit('setPopover', 'ContinueCourse');
                    return true;
                }
            }
            return false;
        },
        login(s, payload) {
            s.commit('setLoginError', null);
            axios.post('/users/login', payload).then( (res) => {
                s.commit('updateUser', res.data);
                stats.add('login', { email:res.data.email }); 

                s.commit('setPopover', null);
                location.hash = '#/programmas';
                s.dispatch('continueCourse');
            }).catch( () => {
                s.commit('setLoginError', 'E-mailadres of wachtwoord onjuist.' );
            });

        },

        register(s, payload) {
            const { input, fields }  = payload;
            try {
                checkInput(input, fields);
                if(input.password != input.password2) {
                    throw Error('Wachtwoorden komen niet overeen.');
                }
                s.commit('setRegisterError', null);
                axios.post('/users/register', input).then( () => {
                    stats.add('register', { email:input.email }); 
                    s.commit('setRegistered', true);
                }).catch( (e) => {
                    s.commit('setRegisterError', translateError(e.response.data));
                });
            } catch(e) {
                s.commit('setRegisterError', e.message);
            }

        },

        logout(s) {
            const user = s.getters.user;
            if(user) {
                stats.add('logout', { email:user.email });
                stats.setLoggedIn(false);
                s.commit('updateUser', null);
                window.location.href = '#/logout';
            }
        },

        removeProfile(s) {
            const user = s.getters.user;
            if(user) {
                stats.add('unregister', { email:user.email });
                axios.delete('/users/'+ user._id).then(  ()=> {
                    s.commit('setPopover', null);
                    s.dispatch('logout');
                });
            }
        },

        setProfile(s, payload) {
            s.commit('updateUser', payload);
            s.commit('setPopover', null);
        },

        updateCourse(s, payload) {
            let update = {};
            if(payload.e === 'set') {
                const { key, val } = payload;
                update[key] = val;
            }
            if(Object.keys(update).length) {

                const user = s.getters.user;
                if(user) {
                    s.commit('updateCourseStatus', {
                        update,
                        course: payload.c,
                    });
                }
            }
        },

        autoLogin(s) {
            const user = sessionStorage.getItem('user');
            if(user) {
                s.commit('updateUser', JSON.parse(user));
                s.dispatch('continueCourse');
            }
        },
		async loadPrograms(s) {
			const { data } = await axios.get('/programs/tree', {
                withCredentials:true, 
			});
            s.commit('setProgramRoot', data );


            const langNames = langs.map(lang => lang.name);
            const map = {};
            const recurse = (node, pathSoFar) => {
                const { id, name, programKey } = node;
                const subPath = slug(name);
                if(id && id.startsWith('WERK-')) {
                    let lang = langNames[0];
                    let path = pathSoFar;
                    if(langNames.includes(name)) {
                        lang = name;
                    } else {
                        path += '/' + subPath;
                    }
                    map[id] = {
                        lang,
                        path,
                    };
                }
                if(programKey && programKey.length) {
                    map[programKey] = {
                        path: pathSoFar + '/' + subPath,
                    };
                }
                if(Array.isArray(node.childs)) {
                    let path = pathSoFar + '/' + subPath;
                    node.childs = node.childs.filter(c => {
                        if(c.enabled) {
                            return c.enabled.werk || (c.enabled.pro || node.enabled.pro);
                        }
                        return true;
                    })
                    node.childs.forEach(child => recurse(child, path));
                }
            }
            const path = '/programmas';
            s.getters.programRoot.childs.forEach(child => recurse(child, path));
            s.commit('setModulesMap', map);
		},
	},

	getters: {
		user: s => s.user,
		loginError: s => s.loginError,
		registerError: s => s.registerError,
		hash: s => s.hash,
        popovers: s => s.popovers,
        popover: s => s.popover,
        pageHash: s => {
            if(!s.hash) {
                return '';
            }
            let result = s.hash.substr(2);
            Object.values(s.popovers).forEach( (val) => {
                if(result.includes(val)) {
                    result = result.substr(0, result.lastIndexOf('/'));
                }
            });
            return result;
        },
        hashParts: (s, getters) => {
			return getters.hash.split('/');
        },

        pageHashParts: (s, getters) => {
			return getters.pageHash.split('/');
        },

        program: (s, getters) => {
            return findProgram( getters.programRoot, getters.pageHashParts );
		},

        courseProgram: (s, getters) => {
            const result = findProgram( getters.programRoot, getters.hashParts );
            return result;
		},

        courseProgress: (s,getters) => (course) => {
            try {
                let result = getters.user.progress[course.id];
                if(result === undefined) {
                    result = getters.user.progress[course.scorm.file];
                }
                if(result === undefined) {
                    throw 'no progress';
                }
                return result;
            } catch(e) {
                return { status:'none' }
            }
        },

        isLanguageParent: (s) => (program) => {
            return (program.childs
                && program.childs.length === 3
                && program.childs[0].name === 'Nederlands');
        },

		programRoot: (s,getters) => {
            return s.programRoot;
        },

        professionalCourses: (s,getters) => {
            return getters.professionalCategory.childs;
        },
        professionalCategory: (s,getters) => {
            return s.programRoot.childs.find(c => c.enabled.pro);
        },

		modulesMap: s => s.modulesMap,
		home: s => s.home,
		registered: s => s.registered,
		phoneView: s => s.phoneView,
		width: s => s.width,
		loggedIn: s => s.user !== null,
		categories: (s,getters) => {
            if(!getters.programRoot) { return []; }
            return getters.programRoot.childs.filter(cat => !cat.enabled.pro);
        },

        content: s => (path) => {
            if(s.content[path] !== undefined) {
                return s.content[path];
            }
            axios.get(path).then( (result) => {
                store.commit('setContent', { path, content:result.data });
            }).catch( () => {
                store.commit('setContent', { path, content:{ title:'Pagina niet gevonden', body:'De door u opgevragen pagina kon niet worden gevonden.' } });
            });
            return null;
        },

        language: (s,gets) => gets.chosenLanguage || 'nl',
        chosenLanguage: s => (s.user && s.user.language) || s.chosenLanguage,
        langName: (s,gets) => (node) => node[gets.language+'_name'] || node.name,
        langTileText: (s,gets) => (node) => node[gets.language+'_tileText'] || node.tileText,
        oefenen: s => {
            return s.oefenen || sessionStorage.getItem('oefenen');
        },
        connect: s => {
            return s.connect || sessionStorage.getItem('connect');
        },

	},
});


export default store;
