<template>
    <popover class="PickLanguage" v-if="page" @close="$emit('close')">
        <h1>{{page.title}}</h1>
        <p v-html="page.body" />
        <languages ref="languages" />
        <div class="row">
            <button class="cancel" @click="cancelClick">Annuleren</button>
            <button class="ok" @click="confirmClick">Opslaan</button>
        </div>
    </popover>
</template>
<script>


import { mapGetters } from 'vuex';
import Popover from './Popover';
import Languages from './Languages';

import langs from './langs';

export default {
    components: {
        Popover,
        Languages,
    },
    data() {
        return {
            active: null,
            langs,
            icons: {
                nl: require('flag-icon-css/flags/4x3/nl.svg'),
                en: require('flag-icon-css/flags/4x3/gb.svg'),
                ar: require('./ar.svg'),
            },
        };
    },
    created() {
        this.active = this.langs[0];
    },
    methods: {
        cancelClick() {
            this.$emit('close');
        },

        confirmClick() {
            const active = this.$refs.languages.active;
            this.$store.dispatch('setLanguage', active.code );
            this.$emit('close');

        },
    },
    computed: {
        ...mapGetters([
            'hash',
            'user',
            'loginError',
            'content',
        ]),
        page() {
            return this.content('/pages/picklang');
        },
    },
};

</script>

<style lang="scss">

.PickLanguage {
    .row {
        margin-top: 20px;
    }
}

</style>

