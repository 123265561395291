<template>
    <div class="TrafficLight">
<svg width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="130 0 192 450" style="enable-background:new 0 0 452.8 452.8;" xml:space="preserve">
<rect x="198.2" y="386" style="fill:#E6E9EE;" width="57.6" height="66.8"/>
<rect x="198.2" y="386" style="fill:#FFFFFF;" width="18" height="66.8"/>
<path style="fill:#676D79;" d="M315.8,354.4c0,17.2-14.4,31.6-31.6,31.6h-116c-17.2,0-31.6-14.4-31.6-31.6V38
c0-17.2,14.4-31.6,31.6-31.6h116c17.2,0,31.6,14.4,31.6,31.6V354.4z"/>
<path style="fill:#898989;" d="M163.8,354.4V38c0-17.2,14.4-31.6,31.6-31.6h-27.6c-17.2,0-31.6,14.4-31.6,31.6v316.4
c0,17.2,14.4,31.6,31.6,31.6h27.6C178.2,386,163.8,371.6,163.8,354.4z"/>

<g fill="#4F424B">
    <circle cx="226.2" cy="86.4" r="44.4"/>
    <circle cx="226.2" cy="197.2" r="44.4"/>
    <circle cx="226.2" cy="308" r="44.4"/>
</g>
<g v-if="color == 'red'">
    <circle style="fill:#EE6E55;" cx="226.2" cy="86.4" r="44.4"/>
    <path style="fill:#ED8577;" d="M204.2,86.4c0-20.8,14-38,33.2-42.8c-3.6-0.8-7.2-1.6-11.2-1.6c-24.4,0-44.4,20-44.4,44.4 s20,44.4,44.4,44.4c4,0,7.6-0.4,11.2-1.6C218.2,124.4,204.2,107.2,204.2,86.4z"/>
</g>
<g v-if="color == 'yellow'">
    <circle style="fill:#FFCE57;" cx="226.2" cy="197.2" r="44.4"/>
    <path style="fill:#FFD985;" d="M204.2,197.2c0-20.8,14-38,33.2-42.8c-3.6-0.8-7.2-1.6-11.2-1.6c-24.4,0-44.4,20-44.4,44.4 s20,44.4,44.4,44.4c4,0,7.6-0.4,11.2-1.6C218.2,235.2,204.2,218,204.2,197.2z"/>
</g>
<g v-if="color == 'green'">
    <circle style="fill:#A1C969;" cx="226.2" cy="308" r="44.4"/>
    <path style="fill:#B2CE84;" d="M204.2,308c0-20.8,14-38,33.2-42.8c-3.6-0.8-7.2-1.6-11.2-1.6c-24.4,0-44.4,20-44.4,44.4 s20,44.4,44.4,44.4c4,0,7.6-0.4,11.2-1.6C218.2,346,204.2,328.4,204.2,308z"/>
</g>
<g>
    <path style="fill:#424953;" d="M284.2,0h-116c-20.8,0-38,17.2-38,38v316.4c0,20.8,17.2,38,38,38h24.4v54c0,3.6,2.8,6.4,6.4,6.4
    c3.6,0,6.4-2.8,6.4-6.4V392h44.4v54.4c0,3.6,2.8,6.4,6.4,6.4c3.6,0,6.4-2.8,6.4-6.4V392h22c20.8,0,38-17.2,38-38V38
    C322.2,17.2,305,0,284.2,0z M309.4,354.4c0,14-11.2,25.2-25.2,25.2h-116c-14,0-25.2-11.2-25.2-25.2V38c0-14,11.2-25.2,25.2-25.2
    h116c14,0,25.2,11.2,25.2,25.2V354.4z"/>
    <path style="fill:#424953;" d="M226.2,36c-28,0-50.8,22.8-50.8,50.8s22.8,50.8,50.8,50.8S277,114.8,277,86.8
    C277,58.4,254.2,36,226.2,36z M226.2,124.4c-20.8,0-38-17.2-38-38s17.2-38,38-38s38,17.2,38,38S247,124.4,226.2,124.4z"/>
    <path style="fill:#424953;" d="M226.2,146.4c-28,0-50.8,22.8-50.8,50.8s22.8,50.8,50.8,50.8s50.8-22.8,50.8-50.8
    S254.2,146.4,226.2,146.4z M226.2,235.2c-20.8,0-38-17.2-38-38s17.2-38,38-38s38,17.2,38,38S247,235.2,226.2,235.2z"/>
    <path style="fill:#424953;" d="M226.2,257.2c-28,0-50.8,22.8-50.8,50.8c0,28,22.8,50.4,50.8,50.4s50.8-22.8,50.8-50.8
    C277,280,254.2,257.2,226.2,257.2z M226.2,346c-20.8,0-38-17.2-38-38s17.2-38,38-38s38,17.2,38,38S247,346,226.2,346z"/>
</g>
</svg>
    </div>
</template>
<script>

export default {
    props: [
        'color',
    ],
};

</script>
<style lang="scss">

.TrafficLight {
    width: 50px;
    height: 116px;
}

</style>

