import SurveyPopup from './components/SurveyPopup.vue';
import store from './store.js';

export default () => {
    /* Disabled on request (https://laveto.monday.com/boards/2471756998/pulses/2535711312)
    if(!localStorage.getItem('survey-first')) {
        localStorage.setItem('survey-first', 'true');
        return;
    }
    if(!localStorage.getItem('survey-choice')) {
        store.commit('setPopover',SurveyPopup);
    }
    */
}

