<template>
	<div v-if="page" class="PageBasic boxed padded">
        <h1>{{page.title}}</h1>
        <p v-html="page.body" />
	</div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
    mounted() {
        this.updateTitle();
    },
    methods: {
        updateTitle() {
            const { page } = this;
            document.title = page ? `${page.title} | WERK-portal.nl` : 'WERK-portal.nl';
        }
    },
    watch: {
        page(nv) {
            this.updateTitle();
        },
    },
    computed: {
        page() {
            if(!this.hash) { return null; }
            return this.content('/pages'+this.hash.substr(1));
        },
        ...mapGetters([
            'content',
            'hash',
        ]),
    },
}

</script>
<style lang="scss">

.PageBasic {
    padding-top: 60px;
    p {
        max-width: 600px;
    }
}

</style>
