<template>
    <div class="Breadcrumbs" :class="{ top:top }">
        <div v-if="crumbs" class="crumbs boxed padded">
            <a v-for="crumb in crumbs" :key="crumb.name"
                :href="crumb.url">
                {{crumb.name}}
            </a>
        </div>
    </div>
</template>
<script>

export default {
    props: [
        'crumbs',
        'top',
    ],
};

</script>
<style lang="scss">

@import "colors.scss";

.Breadcrumbs {
    background: $purple;
    padding: 2px;
    padding-left: 15px;

    &.top {
        padding-top: 20px;

    }

    .crumbs {
        display: flex;
        padding: 8px 0;
    }

    $height: 32px;
    .crumbs>a {
        margin: 4px;
        min-width: 200px;
        padding: 0 8px;
        box-sizing: border-box;
        height: $height;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        font-size: 12px;
        background: rgba(0,0,0,0.3);
        border-top-right-radius: calc($height/2);
        border-bottom-right-radius: calc($height/2);
        text-decoration: none;

        &:last-child {
            border-radius: 0;
            background: rgba(255,255,255,0.4);
        }
    }
}

</style>
