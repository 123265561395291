<template>
    <div class="PageActivate PageBasic">
        <div v-if="page" class="boxed padded">
            <speech :title="page.title" :body="body" />
            <div v-if="error" class="error">{{error}}</div>
        </div>
    </div>
</template>
<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import Speech from './Speech';

import axios from 'axios';
export default {
    components: {
        Speech,
    },

    data() {
        return {
            activated:false,
            error:null,
        };
    },
    created() {
        axios.post('/users/activate', { token:this.token }).then( ()=> {
            this.activated = true;
        }).catch( (e) => {
            this.error = 'De inschrijving is niet gelukt, probeer het opnieuw.';
        });
    },

    computed: {
        body() {
            if(this.activated) {
                return this.page.body;
            }
            return 'Bezig met activeren...';
        },
        token() {
            return window.location.hash.match(/[A-z0-9]+$/)[0]
        },
        page() {
            return this.content('/pages/activate');
        },
        ...mapGetters([
            'content',
        ]),
    }
};

</script>
<style lang="scss">

.PageActivate {
    .error {
        background: #b00;
        color: white;
        padding: 8px;
    }

}

</style>
