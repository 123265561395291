<template>
    <button class="BackButton"
        @keyup.enter="backClick"
        @click="backClick"
        tabindex="0">
		<icon name="arrow-left" scale="3" />
	</button>
</template>
<script>


import { mapGetters } from 'vuex';
import 'vue-awesome/icons/arrow-left';

export default {
	methods: {
		backClick() {
            history.back();
		},
	},
    computed: {
        ...mapGetters([
            'pageHashParts',
        ]),
    },
}

</script>
<style lang="scss">

@import "colors.scss";

$size: 64px;
.BackButton {
    cursor: pointer;
    width: $size;
    height: $size;
    background: $green;
    position: absolute;
    left: 0;
    border-radius: 50%;
    border: 0;
    color: $lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
