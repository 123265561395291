<template>
    <popover v-if="page" class="Profile">
        <h1>{{page.title}}</h1>
        <template v-if="removeMode">
            <p>Weet je zeker?</p>
            <label>
            <input type="checkbox" v-model="confirmRemove" /> Ja, ik wil mijn profiel en alle voortgang verwijderen.
            </label>
            <div class="row space">
                <button class="cancel" @click="removeMode = false">Annuleren</button>
                <button @click="removeProfile">Profiel verwijderen</button>
            </div>
        </template>
        <template v-else>
            <p v-html="page.body" />
            <div v-for="f in fields" class="row">
                <label :for="f.name">{{f.label}}</label>
                <input :id="f.name" v-model="input[f.name]" :type="f.type" />
            </div>
            <div class="row">
                <label for="select-lang">Taal</label>
                <select id="select-lang" v-model="languageSetting">
                    <option v-for="lang in langs" :value="lang.code">
                    {{lang.name}}
                    </option>
                </select>

            </div>

            <div class="row right">
                <label>
                    <input type="checkbox" v-model="input.newsletter" />Ja, ik meld mij aan voor de nieuwsbrief
                </label>
            </div>
            <div class="row space">
                <button class="cancel" @click="removeMode = true">Profiel verwijderen</button>
                <button @click="saveClick">Wijzigingen opslaan</button>
            </div>
        </template>
    </popover>
</template>
<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';

import Popover from './Popover';

import langs from './langs';

const field = (label,name,type='text') => ({ label, name, type });

export default {
    components: {
        Popover,
    },
    data() {
        return {
            langs,
            removeMode: false,
            confirmRemove: false,
            fields: [
                field('Voornaam', 'firstname'),
                field('Achternaam', 'lastname'),
            ],
            input: {
                agreedTerms: false,
                newsletter: false,
            },
        };
    },

    created() {
        this.$store.commit('setRegisterError', null);
        const user = this.user;
        this.fields.forEach( f => Vue.set(this.input, f.name, user[f.name] ) );
        this.input.newsletter = user.newsletter;
    },
    methods: {
        removeProfile() {
            if(this.confirmRemove) {
                this.$store.dispatch('removeProfile');
            }
        },
        saveClick(){
            this.$store.dispatch('setProfile', this.input);
        },
    },
    computed: {
        ...mapGetters([
            'registered',
            'registerError',
            'content',
            'user',
            'language',
        ]),

        languageSetting: {
            get() {
                return this.language;
            },

            set(to) {
                this.$store.dispatch('setLanguage', to);
            },
        },
        page() {
            return this.content('/pages/profile');
        },
    },
};

</script>

<style lang="scss">

.Profile {
    .space {
        display: flex;
        justify-content: space-between;
    }

    button {
        margin-left: 0 !important;
    }
}

</style>

