import sendResults from './send-results';
import homeworkDone from './homework-done';
import axios from 'axios';
import store from './store';
import courseProgressInfo from './course-progress-info';
export default class OefenenStorage {
    constructor(oefenen, course) {
        this.oefenen = oefenen;
        this.course = course;
        this.userKey = oefenen.match(/UserKey=([^&]+)/)[1];
        this.data = {};
        this.url = '/oefenen/'+this.userKey+'-'+course.id;
    }
    async load() {
        const res = (await axios.get(this.url));
        try {
            this.data = JSON.parse(res.data.json);

            //prevent scoring of other course
            delete this.data['cmi.core.score.raw'];
            delete this.data['cmi.score.raw'];
        } catch(e) {
            this.data = {};
        }
    }

    invalidate() {
        if(this.saveId !== undefined) {
            return;
        }
        this.saveId = setTimeout(async() => {
            this.saveId = undefined;
            const { userKey, course, data } = this;
            axios.patch(this.url, {
                json:JSON.stringify(data)
            });

            let isFinished = courseProgressInfo(data) === 2;
            if(!isFinished) {
                let score = this.data['cmi.core.score.raw'] || this.data['cmi.score.raw'];
                if(score !== undefined) {
                    if(parseInt(score) >= 60) {
                        isFinished = true;
                    }
                }
            }
            if(isFinished) {
                homeworkDone(course);
                sendResults(userKey, course, data, true);
            }

        },1000);
    }

    get(key) {
        return this.data[key] || '';
    }

    set(key,val) {
        store.dispatch('updateCourse', {
            c:this.course,
            e:'set',
            key,
            val,
        });
        this.data[key] = val;
        this.invalidate();
        return 'true';
    }

}
