<template>
	<div class="HomePrograms">
		<div class="boxed padded">
			<search-input v-if="phoneView" v-model="needle" />
			<h1>Alle programma’s</h1>
			<search-input v-if="!phoneView" v-model="needle" />
			<div class="cat" v-for="cat in filtered" :key="cat.name">
                <program-category-title :cat="cat" />
                <div class="programs">
				<program-tile
					v-for="program in cat.childs"
					:key="program.name"
                    :href="programHref(program)"
					:program="program" />
                </div>
			</div>
		</div>
	</div>
</template>
<script>

import ProgramCategoryTitle from './ProgramCategoryTitle';

import SearchInput from './SearchInput';

import { mapGetters } from 'vuex';

import ProgramTile from './ProgramTile';
import 'vue-awesome/icons/search';
import slug from './slug';

export default {
	data() { return {
		needle: null,
	}},
	components: {
        ProgramCategoryTitle,
        SearchInput,
		ProgramTile,
	},

	methods: {
        programHref(program) {
            const cat = this.categories.find( cat => cat.childs.indexOf(program) != -1);
			return '#/' + [
				'programmas',
                slug(cat.name),
                slug(program.name),
			].join('/');
        },
	},

	computed: {
		filtered() {
			if(!this.needle) {
				return this.categories;
			}
			const letters = this.needle.toLowerCase().split(' ');
			const resultsCat = {
				name: 'Zoekresultaten',
				childs: [],
			};
			this.categories.forEach( (cat) => {
				cat.childs.forEach( (program)=> {
					const haystack = (program.name + ' '+program.intro).toLowerCase();
					let prev = -1;
					for(let i=0; i<letters.length; i++) {
						let idx = haystack.indexOf(letters[i], prev+1);
						if(idx > prev) {
							prev = idx;
						} else {
							return;
						}
					}
					resultsCat.childs.push( program );
				});
			});
			return [ resultsCat ];
		},
		...mapGetters([
			'categories',
            'phoneView',
		]),
	}
}


</script>
<style lang="scss">


@import "colors.scss";
@import "variables.scss";

.HomePrograms {
    .SearchInput {
        position: absolute;
        right: 0;
        top: 30px;
    }
	background: white;
	margin-bottom: 50px;

	h1 {
		margin-bottom: 28px;
	}

    .cat {
        margin: 0 auto;
    }


    @media (min-width: 1010px) {
        .cat {
            width: calc(6*160px + 5*6px);
            margin: 0;
        }
    }

    @media (min-width: 844px) and (max-width: 1009px) {
        .cat {
            width: calc(5*160px + 4*6px);
        }
    }
    @media (min-width: 678px) and (max-width: 843px) {
        .cat {
            width: calc(4*160px + 3*6px);
        }
    }
    @media (min-width: 512px) and (max-width: 677px) {
        .cat {
            width: calc(3*160px + 2*6px);
        }
    }
    @media (max-width: 511px) {
        .cat {
            width: calc(2*160px + 1*6px);
        }
    }
    @media (max-width: 346px) {
        .cat {
            width: auto;
        }
    }
    @media (max-width: $phone) {
        .SearchInput {
            position: static;
            margin-left: auto;
        }
        h1 {
            margin-bottom: 8px;
        }
    }
    .programs {
        margin-right: -6px;
    }


}

</style>
