<template>
	<div class="SearchInput">
		<input aria-label="Zoeken" placeholder="Zoeken..." v-model="needle" />
		<div class="icon">
			<icon name="search" />
		</div>
	</div>
</template>
<script>

export default {
	props: [ 'value' ],
	computed: {
		needle: {
			get:function() {
				return this.value;
			},

			set:function(to) {
				this.$emit('input', to);
			},
		}
	},

}

</script>
<style lang="scss">

@import "colors.scss";

.SearchInput {
	width: 320px;
	height: 46px;
	background: $purple;
	border-radius: 10px;
	padding: 7px 10px;
	box-sizing: border-box;
    display: flex;

	$height: 32px;

	input {
		font-size: 16px;
		max-width: 260px;
        flex: 1;
		height: $height;
		line-height: $height;
		box-sizing: border-box;
		padding-left: 6px;
		border: 0;
		border-radius: 4px;

	}
	::-webkit-input-placeholder { color: $purple; }
	::-moz-placeholder { color: $purple; }
	:-ms-input-placeholder { color: $purple; }
	:-moz-placeholder { color: $purple; }

	.icon {
		display: inline-block;
		width: $height;
		height: $height;
		background: rgba(255,255,255,0.4);
		vertical-align: top;
		margin-left: 8px;
		color: white;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
	}
}
</style>
