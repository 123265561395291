<template>
    <span class="MyResultProgress">
        <span class="box">
            <div class="fill" :style="fillStyle" />
       </span>
    </span>
</template>
<script>

import courseProgressInfo from './course-progress-info';

export default {
    props: [
        'node',
    ],
    computed: {
        fillStyle() {
            let s = this.node.totalProgress;
            return {
                transform: 'scale('+s+',1)',
            };
        },
    },
};


</script>
<style lang="scss">

@import "colors.scss";

.MyResultProgress {
    .box {
        display: inline-block;
        width: 120px;
        height: 20px;
        border: 1px solid #D6D6D6;
        background: white;
        vertical-align: top;
    }

    .fill {
        display: block;
        width: 120px;
        height: 100%;
        background: $purple;
        transform-origin: left center;
    }

}

</style>
