<template>
    <div class="PageOrganisationsAbout PageBasic">
        <breadcrumbs v-if="!phoneView" :crumbs="crumbs" top="true" />
        <div v-if="page" class="boxed padded">
            <speech :title="page.title" :body="page.body" />
            <youtube :src="page.youtube" :size="youtubeSize" width="416" height="234" />
            <youtube :src="page.youtube2" :size="youtubeSize" width="416" height="234" />
            <p class="invert" v-rich="page.block"></p>
            <document-list :categories="page.categories" />
        </div>
        <div class="boxed bottom-back">
            <back-button />
        </div>
    </div>
</template>
<script>
import Breadcrumbs from './Breadcrumbs';

import { mapGetters } from 'vuex';
import DocumentList from './DocumentList';
import Speech from './Speech';
import BackButton from './BackButton';
import Youtube from './Youtube';

export default {
    components: {
        Breadcrumbs,
        DocumentList,
        Speech,
		BackButton,
        Youtube,
    },
    computed: {
        page() {
            return this.content('/pages/organisations-about');
        },
        categories() {
            return this.page.categories;
        },
        youtubeSize() {
            if(!this.phoneView) {
                return { width:416, height:234 }
            }
            return { width:this.innerWidth, height:(234/416)*this.innerWidth };
        },

        innerWidth() {
            return this.width-20;
        },
        ...mapGetters([
            'content',
            'phoneView',
            'width',
        ]),

        crumbs() {
            if(!this.page) {
                return [];
            }
            return [
                { name:'Voor organisaties', url:'#/voor-organisaties' },
                { name:this.page.title, url:'#/voor-organisaties/over' },
            ];
        }

    },
};

</script>
<style lang="scss">

@import "colors.scss";

.PageOrganisationsAbout {
    padding-top: 0 !important;

    .invert {
        background: $darkgray;
        color: white;
        padding: 16px;
        max-width: 820px;
        margin: 32px 0;
        a {
            color: white;
        }
    }

    .Youtube {
        display: inline-block;
        background: black;
        margin-right: 20px;
        vertical-align: top;
    }
}

</style>

