<template>
    <div class="SplitBar">
        <div class="left">
            <slot name="left" />
        </div>
        <button v-if="rightIsButton" class="right"
            tabindex="0"
            @keyup.enter="$emit('click')"
            @click="$emit('click')"
        >
            <slot name="right" />
        </button>
        <div v-else class="right">
            <slot name="right" />
        </div>
    </div>
</template>
<script>

export default {
    props: [
        'rightIsButton',
    ],
};

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.SplitBar {
    display: flex;
    .left, .right {
        height: 46px;
        color: white;
        position: relative;
        padding-left: 16px;
    }
    .left {
        display: flex;
        align-items: center;
        background: $green;
        flex: 1;
        span {
            margin-left: 10px;
        }
    }
    .right {
        display: flex;
        align-items: center;
        font-size: 18px;

        background: $purple;
        width: 280px;
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;

    }
    button {
        cursor: pointer;
        justify-content: center;
        font-weight: bold;
        transition: background 0.2s;
        &:hover, &:focus {
            background: lighten($purple,10);
        }
    }

    @media (max-width: $phone) {
        & {
            display: block;
        }
        .left, .right {
        }
        .left span {
            margin-left: 0px;
        }
        .right {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            width: 100%;
        }
    }

}

</style>
