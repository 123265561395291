<template>
    <div class="PageOrganisationsExtra PageBasic">
        <breadcrumbs v-if="!phoneView" :crumbs="crumbs" top="true" />
        <div v-if="page" class="boxed padded">
            <speech :title="page.title" :body="page.body" />

            <label for="program">Selecteer een programma</label>
            <document-list :categories="categories">
                <select id="program" v-model="selected">
                    <option :value="null">---</option>
                    <option v-for="program in page.categories" :key="program.name" :value="program">{{program.name}}</option>
                </select>
            </document-list>
        </div>
        <div class="boxed bottom-back">
            <back-button />
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import DocumentList from './DocumentList';
import Breadcrumbs from './Breadcrumbs';
import Speech from './Speech';
import BackButton from './BackButton';

export default {
    data() {
        return {
            selected: null,
        };
    },
    components: {
        Breadcrumbs,
        DocumentList,
        Speech,
		BackButton,
    },
    computed: {
        page() {
            return this.content('/pages/organisations-extra');
        },
        categories() {
            if(this.selected) {
                return [ this.selected ];
            }
            return this.page.categories;
        },
        ...mapGetters([
            'content',
            'phoneView',
		]),
        crumbs() {
            if(!this.page) {
                return [];
            }
            return [
                { name:'Voor organisaties', url:'#/voor-organisaties' },
                { name: this.page.title, url:'#/voor-organisaties/extra' },
            ];
        }

    },
};

</script>
<style lang="scss">

.PageOrganisationsExtra {
    padding-top: 0 !important;
    select {
        width: 320px;
        height: 46px;
        font-size: 16px;
        margin-right: 20px;
        vertical-align: top;
    }
    label {
        display: block;
        padding-bottom: 10px;
    }

    .SearchInput {
        display: inline-block;
    }
}

</style>

