import stats from './stats';
import decode from './decode';
import axios from 'axios';
axios.defaults.baseURL = '/api';

import courseProgressInfo from './course-progress-info';

class SCORMAPI {
    constructor(token, courseId, userId, storage) {
        this.token = token;
        this.courseId = courseId;
        this.userId = userId;
        this.storage = storage;
        this.initTime = new Date();

        if(!storage) {
            this.storage = {};
            this.progress = 0;
            this.outputProgress();
        } else {
            
            this.progress = courseProgressInfo(this.storage);
            this.outputProgress();
        }
    }
	Initialize() {
        this.initTime = new Date();
        this.SetValue('started', 1);
        stats.add('course.init', { course:this.getCourseId() });
        return 'true';
	}

	Terminate() {
        try {
            const now = new Date();
            const secondsElapsed = Math.round( (now.getTime() - this.initTime.getTime())/1000 );
            stats.add('course.terminate', { course:this.getCourseId(), sessionTime:secondsElapsed });

            const { userId, courseId } = this;
            const data = {
                user_id: userId,
                manual: courseId,
            }
            axios.post('https://educentre.nl/api/v2/work-portals/exit', data);
        } catch(e) {
            
        }
	}

	GetValue(key) {
        if(key in this.storage) {
            return this.storage[key];
        }
        return '';
	}

	SetValue(key,val) {
        if(this.storage[key] !== val) {
            this.storage[key] = val;
            const progress = courseProgressInfo(this.storage);
            if(progress === 2 && !this.storage.werkFinished) {
                this.storage.werkFinished = true;
                stats.add('course.finish', { course:this.getCourseId() });
            }
            if(progress !== this.progress) {
                this.progress = progress;
                this.outputProgress();
            }

            this.requestSave();
        }
        return 'true';
	}

    requestSave() {
        if(this.saveId !== undefined) { return; }
        this.saveId = setTimeout(() => {
            const { token, courseId } = this;
            this.saveId = undefined;
            axios.put(`/educentre/${token}/${courseId}`, this.storage);
        },500);
    }

	Commit() {
        return 'true';
	}

	GetLastError() {
        return 0;
	}

	GetErrorString() {
	}

	GetDiagnostic() {
	}
    LMSInitialize() { return this.Initialize(); }
    LMSFinish() { return this.Terminate(); }
    LMSGetValue(key) { return this.GetValue(key); }
	LMSSetValue(key,val) { return this.SetValue(key,val); }
	LMSCommit() { return this.Commit(); }
	LMSGetLastError() { return this.GetLastError(); }
	LMSGetErrorString() { return this.GetErrorString(); }
	LMSGetDiagnostic() { return this.GetDiagnostic(); }

    outputProgress() {
        window.parent.postMessage({
            course: this.getCourseId(),
            progress: this.progress*50,
        },'*');
    }

    getCourseId() {
        return this.courseId;
    }
}

const addStyle = () => {
    const style = document.createElement('style');
    style.innerHTML = `
    html, body, iframe {
    width: 100%;
    height: 100%;
    }

    iframe {
        border: 0;
    }
    `;
    document.body.appendChild(style);
}


export default async() => {
    const [code,courseCode] = location.search.match(/launch=([^&]+)/)[1].split('.');
    const token = location.search.match(/token=([^&]+)/)[1];
    const courseId = decode(courseCode);

    try {
        await axios.post('/educentre/validate', { token, courseId });
    } catch(e) {
        return;
    }
    const storage = (await axios.get(`/educentre/${token}/${courseId}`)).data;
    stats.source = 'educentre';


    const userId = atob(token).substr(36);
    stats.setId(userId);
    stats.add('visit', {});

    const api = new SCORMAPI(token, courseId,userId, storage);
    window.API = api;
    window.API_1484_11 = api;

    addStyle();

    const iframe = document.createElement('iframe');
    const zip = '/static/'+code+'.zip';
    const url = '/api/programs/index'+zip;
    iframe.src = url;
    document.body.appendChild(iframe);
}
