<template>
	<a class="ProgramTile" :href="href">
        <div class="image" :style="'background-image: url('+program.image+')'"></div>
        <div class="play">
			<svg width="100%" height="100%" viewBox="-5 -5 20 20" aria-label="Details cursus">
				<path d="M3,0 l0,10 7,-5z" fill="white" />
			</svg>
		</div>
		<h3>{{langName(program)}}</h3>
		<p>{{langTileText(program)}}</p>
	</a>
</template>
<script>

import CourseStatus from './CourseStatus';
import { mapGetters } from 'vuex';

export default {
    components: {
        CourseStatus,
    },
	props: [
		'program',
		'href',
	],

	computed: {
        ...mapGetters([
            'langName',
            'langTileText',
        ]),
	},
};

</script>
<style lang="scss">

@import "colors.scss";

.ProgramTile {
    width: 160px;
	height: 260px;
	margin-right: 6px;
	margin-bottom: 6px;
	vertical-align: top;
	display: inline-block;
	background: $lighterpurple;
	position: relative;
    font-size: 16px;

    text-decoration: none;
	.image {
		min-width: 160px;
		height: 128px;
		background: black;
	}


	$size: 32px;

	.play {
		width: $size;
		height: $size;
		background: purple;
		position: absolute;
		left: calc(80px - $size/2);
		margin-top: calc($size / -2 - 2px);
		border-radius: 50%;
	}

	h3 {
		font-size: 16px;
		text-align: center;
		padding: 4px;
		min-height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 16px 0;
		margin-bottom: 0;
	}

	p {
		margin: 0;
		padding: 0;
		height: 70px;
		overflow: hidden;

		text-align: center;
	}
	cursor: pointer;
	&:hover, &:focus {
		background: #DDC3DD;
		h3 {
			color: $purple;
		}
	}

}

</style>
