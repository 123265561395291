
function slug(str) {
    str = str.toLowerCase();
    str = replaceDiacritics(str);
    str = str.replace(/[^a-z0-9]+/g, '-');
    if(str.lastIndexOf('-') === str.length-1) {
        str = str.substr(0, str.length-1);
    }
    return str;
}

function replaceDiacritics(s) {
    var diacritics =[
        /[\340-\346]/g,
        /[\350-\353]/g,
        /[\354-\357]/g,
        /[\362-\370]/g,
        /[\371-\374]/g,
        /[\361]/g,
        /[\347]/g,
    ];
    var chars = [ 'a', 'e', 'i', 'o', 'u', 'n', 'c'];
    for (var i = 0; i < diacritics.length; i++) {
        s = s.replace(diacritics[i],chars[i]);
    }
    return s;
}

export default slug;


