<template>
	<div v-if="page" class="PageFaq PageBasic boxed padded">
        <speech :title="page.title" :body="page.body" />
        <div class="tabs"
            tabindex="0" aria-role="tablist"
            @keyup.left="focusTab(-1)" @keyup.right="focusTab(+1)">
            <div v-for="cat in cats" :key="cat.name" aria-role="tab"
                 @click="catClick(cat)"
                 :aria-selected="cat == activeCat"
                 :class="{ tab:1, active:activeCat==cat }">
                {{catName(cat)}}
            </div>
        </div>

        <div v-if="activeCat" class="faqs"
            aria-role="tabpanel" :aria-labelledby="catName(activeCat)" tabindex="0"
            @keyup.left="focusFaq(-1)" @keyup.right="focusFaq(+1)">
            <h2>Wat is je vraag?</h2>
            <div v-for="(faq,i) in faqs" :key="faq.question" class="faq">
                <div @click="toggleFaq(faq)" class="question" aria-role="tab"
                 :aria-selected="faq == activeFaq">{{faq.question}}</div>
                <div v-if="faq == activeFaq" class="answer" v-html="finishHTML(faq.answer)"
                    aria-role="tabpanel"></div>
            </div>
        </div>
	</div>
</template>
<script>
import Speech from './Speech';


import { mapGetters } from 'vuex';
import slug from './slug.js';


const loop = (idx, dir, arr) => {
    let result = idx + dir;
    if(result < 0) { return arr.length-1; }
    if(result >= arr.length) { return 0; }
    return result;
}
export default {
    components: {
        Speech,
    },
    computed: {
        activeCat() {
            const { parts } = this;
            return this.cats.find(c => c.slug === parts[1]);
        },
        activeFaq() {
            const { parts } = this;
            return this.faqs.find(c => c.slug === parts[2]);
        },
        cats() {
            if(!this.page) { return []; }
            return this.page.categories.map(c => {
                return { ...c, slug:slug(c.name) };
            });
        },
        faqs() {
            const { activeCat } = this;
            if(activeCat === null) { return []; }
            return activeCat.faqs.map(f => {
                return { ...f, slug:slug(f.question) };
            });
        },
        parts() {
            return this.pageHash.split('/');
        },
        page() {
            return this.content('/pages/faq');
        },
        ...mapGetters([
            'content',
			'pageHash',
        ]),
    },

    methods: {
        focusTab(dir) {
            const { cats, activeCat } = this;
            const idx = loop(cats.indexOf(activeCat), dir, cats);
            this.catClick(cats[idx]);
        },
        focusFaq(dir) {
            const { faqs, activeFaq } = this;
            const idx = loop(faqs.indexOf(activeFaq), dir, faqs);
            this.toggleFaq(faqs[idx]);
        },
        catName(cat) {
            return cat.name.replace(/Computerinstellingen/, "Computer\u00ADinstellingen");
        },
        toggleFaq(faq) {
            if(this.activeFaq === faq) {
                this.route(2, null);
            } else {
                this.route(2, faq.slug);
            }
        },
        finishHTML(str) {
            return str.replace(/<a href="http/g, '<a target="_blank" href="http');
        },
        catClick(cat) {
            this.route(1, cat.slug + '/' + slug(cat.faqs[0].question));
        },
        route(keepToIndex, addPart) {
            const { parts } = this;
            const comps = parts.slice(0,keepToIndex);
            if(addPart) {
                comps.push(addPart);
            }
            location.hash = '#/' + comps.join('/');
        }
    },
}

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.PageFaq {
    .tabs {
        display: flex;
    }
    .tab {
        flex: 1;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        @media (max-width: $phone) {
            font-size: 13px;
        }
        background: $lightgray;
        cursor: pointer;

        word-wrap: break-word;
        min-width: 1px;

        border-right: 1px solid white;
        &:first-child {
            border-left: 1px solid $lightgray;
        }

        &:last-child {
            border-right: 1px solid $lightgray;
        }
        &.active {
            border-top: 1px solid $lightgray;
            background: white;
            color: $purple;
            font-weight: bold;
        }
    }

    .faqs {
        padding: 20px;
        border: 1px solid $lightgray;
        border-top: 0;
        h2 {
            margin-bottom: 20px;
        }
    }

    .faq {
        margin: 4px 0;
    }

    .question {
        cursor: pointer;
        font-weight: bold;
        background: $lightgray;
        padding: 8px;
    }

    .answer {
        background: white;
        padding: 8px;

    }

}

</style>
