<template>
    <div class="Youtube">
        <iframe :width="size.width" :height="size.height" :src="autoplaySource" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
    </div>
</template>
<script>

import 'vue-awesome/icons/brands/youtube';

export default {
    props: [ 'src', 'size', 'width', 'height' ],
    data() {
        return {
            clicked: false,
        };
    },

    computed: {
        previewStyle() {
            return {
                backgroundImage: 'url('+this.thumbURL+')',
                width: this.width+'px',
                height: this.height+'px',
            };
        },
        autoplaySource() {
            return this.src + '?title=0&byline=0&portrait=0';
        },
        thumbURL() {
            const match = this.src.match(/embed\/([^?&]+)/);
            if(match) {
                return `https://i.ytimg.com/vi/${match[1]}/hqdefault.jpg`
            }
            return null;
        }
    }
};

</script>
<style lang="scss">

.Youtube {
    background: black;
    .preview {
        background: center no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .fa-icon {
            color: rgba(0,0,0,0.7);
        }
        &:hover {
            .fa-icon {
                color: red;
            }
        }
    }
}

</style>
