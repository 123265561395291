<template>
	<div class="PageHome">
		<home-welcome />
		<home-programs />
	</div>
</template>
<script>

import HomeWelcome from './HomeWelcome'
import HomePrograms from './HomePrograms'

export default {
	components: {
		HomeWelcome,
		HomePrograms,
	}
}

</script>
<style lang="scss">

.PageHome {

}

</style>
