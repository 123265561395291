import getChapterAndModuleIds from './get-chapter-and-module-ids';
import store from './store';

export default (course, endpoint) => {
    const { chapterId, moduleId } = getChapterAndModuleIds(course);

    let url = [
        'https://oefenen.nl/api.php?Action='+endpoint+'&',
        store.getters.oefenen.substr(1)
    ].join('');

    if(!url.includes('&ChapterID')) {
        url += '&ChapterID='+chapterId;
    }
    if(!url.includes('&ModuleID')) {
        url += '&ModuleID='+moduleId; 
    }
    return url;
}

