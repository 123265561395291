<template>
    <div class="PageReset PageBasic" v-if="page">
        <div v-if="!complete" class="boxed padded">
            <speech :title="page.title" :body="page.body" />
            <form @submit.prevent="submit">
                <div v-for="f in fields">
                    <label :for="f.name">{{f.label}}</label>
                    <input :id="f.name" v-model="input[f.name]" :type="f.type" />
                </div>
                <div v-if="error" class="error">{{error}}</div>

                <div class="pad-left">
                    <input class="button" type="submit" value="verzenden" />
                </div>
            </form>
        </div>
        <div v-else class="boxed padded">
            <h1>Wachtwoord gewijzigd</h1>
            <p>Je wachtwoord is gewijzigd, je kunt nu opnieuw inloggen.</p>
        </div>
    </div>
</template>
<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import Speech from './Speech';

const field = (label,name,type) => ({ label, name, type });
const axios = require('axios');
import translateError from './translate-error';

export default {
    components: {
        Speech,
    },

    created() {
        this.fields.forEach( f => Vue.set(this.input,f.key,null) );
    },

    data() {
        return {
            input: {
                password:null,
                password2:null,
            },
            fields: [
                field('Wachtwoord', 'password', 'password'),
                field('Wachtwoord herhalen', 'password2', 'password'),
            ],
            error: null,
            complete: false,
        };
    },
    methods: {
        submit() {
            const { input, fields }  = this;
            try {
                const args = Object.assign(input, { resetToken:this.resetToken });
                axios.post('/users/reset', args).then( (res) => {
                    this.complete = true;
                }).catch( (e) => {
                    this.error = translateError(e.response.data);
                });
            } catch(e) {
                this.error = e.message;
            }
        },
    },
    computed: {
        resetToken() {
            return location.hash.match(/[^\/]+$/)[0];
        },
        page() {
            return this.content('/pages/reset');
        },
        ...mapGetters([
            'content',
        ]),
    }
};

</script>
<style lang="scss">

.PageReset {
    form>div {
        margin-bottom: 4px;
    }

    label {
        display: inline-block;
        width: 160px;
        vertical-align: top;
    }

    input:not([type=checkbox]), textarea {
        width: 300px;
        border: 1px solid #ddd;
        padding: 8px;
        box-sizing: border-box;
    }
    textarea {
        height: 160px;
    }

    .button {
        display: block;
        margin-top: 10px;
    }

    .pad-left {
        padding-left: 160px;
    }
    .error {
        width: 440px;

    }

}

</style>
