import Vue from 'vue'
import Icon from 'vue-awesome/components/Icon'
import nanoid from 'nanoid'

import store from './store';
import App from './App'
import previewScorm from './preview-scorm';
import professionalScorm from './professional-scorm';
import launch from './launch-educentre';
import axios from 'axios'

require('./sentry.js');

axios.defaults.baseURL = '/api';

if(window.location.search.includes('launch=')) {
    launch();
} else if(window.location.search.includes('professional=')) {
    professionalScorm();
} else if(window.location.search.includes('preview_scorm=')) {
    previewScorm();
} else {
    if(document.cookie.indexOf('uid') === -1) {
        document.cookie += 'uid='+nanoid(10);
    }

    (async() => {
        store.dispatch('autoLogin');
        await store.dispatch('loadPrograms');
        Vue.config.productionTip = false
        Vue.component('icon', Icon)

        function  update(el, binding, vnode) {
            let html = binding.value;
            if(html) {
                html = html.replace(/a href="http[^"]+"/, (match) => match + ' target="_blank"');
                el.innerHTML = html;
            }
        }
        Vue.directive('rich', {
            bind: update,
            update,
        });

        new Vue({
            render: h => h(App),
            store,
        }).$mount('#app')
    })();
}
