<template>
    <div class="MyResultRow"
        :class="leaf ? 'leaf' : 'branch'"
         @click.stop="rowClick">
        <div v-if="depth === 0" class="bar theme">{{node.name}}</div>
        <div v-else class="bar">
            <span>
                <span class="inset" :style="inset" />
                <svg v-if="!leaf" :class="{ arrow:1, open:open }" viewBox="0 0 10 10">
                    <path d="m2,1l6,4 -6,4z" fill="#666" />
                </svg>
                {{node.name}}
            </span>
            <span>
                <template v-if="leaf">{{lastVisit}}</template>
                <span v-else class="link">
                    {{open ? 'Verberg' : 'Toon'}} onderdelen
                </span>
            </span>
            <my-result-state v-if="leaf" :node="node" />
            <my-result-progress v-else :node="node" />
            <span>{{testResult}}</span>
            <div v-if="leaf" @click="start" class="button">
                START
            </div>


           
        </div>
        <template v-if="!leaf && open">
            <my-result-row v-for="c in node.childs" :key="c.id"
                           :allOpen="allOpen"
                :node="c" :depth="depth+1" />
        </template>
    </div>
</template>
<script>


import MyResultProgress from './MyResultProgress';
import MyResultState from './MyResultState';

import { mapGetters } from 'vuex';
import openScorm from './open-scorm';

export default {
    components: {
        MyResultProgress,
        MyResultState,
    },
    name: 'my-result-row',
    props: [
        'node',
        'depth',
        'allOpen',
    ],

    data() {
        return {
            localOpen: false,
        };
    },

    created() {
        this.localOpen = (this.depth === 0) ? true : this.allOpen;
    },

    computed: {
        open() {
            return this.localOpen;
        },
        ...mapGetters([
            'courseProgress',
        ]),
        testResult() {
            if(this.node.score) {
                return this.node.score + '%';
            }
            return '';
        },
        leaf() {
            return this.node.id !== undefined;
        },
        inset() {
            return {
                width: (this.depth*16)+'px',
            };
        },

        lastVisit() {
            if(!this.node.visit) { return '' };
            const parts = this.node.visit.split('-')
            parts.reverse();
            return parts.join('-');
        },
    },

    methods: {
        start() {
            openScorm(this.node);
        },
        rowClick() {
            this.localOpen = !this.localOpen;
        },
    }

};

</script>
<style lang="scss">

.MyResultRow {
    .inset {
        display: inline-block;
    }
    &.leaf {
        background: #F7F5F7;
    }
    &.branch {
        background: #E6E6E6;
        cursor: pointer;
    }
    .arrow {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        transform-origin: center;
    }
    .arrow.open {
        transform: rotate(90deg);
    }

    .bar>span {
        display: inline-block;
    }
    .link {
        text-decoration: underline;
        cursor: pointer;
    }
    .button {
        display: inline-block;
        float: right;
        margin-top: -4px;
        background-image: url('./arrow.svg');
        background-repeat: no-repeat;
        background-size: 13px;
        background-position: 56px 9px;
        padding-right: 26px;
        img {
            vertical-align: middle;
            margin-left: 10px;
        }
    }
}

</style>
