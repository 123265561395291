import stats from './stats.js';
import connectStorage from './connect-storage.js';
import UserStorage from './UserStorage';
import OefenenStorage from './OefenenStorage';
import store from './store';

import SCORMAPI from './SCORMAPI';


export default async(course) => {
    store.dispatch('setLastCourse', course);

    if(course.scormExternal) {
        stats.add('course.init', { course:course.id });
        store.dispatch('updateCourse', {
            c:course,
            e:'set',
            key: 'cmi.core.lesson_status',
            val: 'browsed',
        });

        let scormExternal = course.scormExternal
            .replace('www.werk-portal.nl', document.location.host)
            .replace('werk-portal.nl', document.location.host);

        window.open(scormExternal);
        return ;
    }


    let storage;
    const { oefenen, connect } = store.getters;
    if(oefenen) {
        storage = new OefenenStorage(oefenen, course);
        await storage.load();
    } else if(connect) {
        storage = connectStorage;
    } else {
        storage = new UserStorage(course);
    }
    const api = new SCORMAPI(course, storage);
    window.API = api;
    window.API_1484_11 = api;
    const zip = course.scorm.file;
    const url = '/api/programs/index'+zip;
    api.win = window.open(url, '', '');
};




