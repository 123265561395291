<template>
    <div class="SurveyPopupRole">
        <div class="img" :style="imageStyle" />
        <div class="description">
            {{role.description}}
        </div>
    </div>
</template>
<script>
export default {
    props: [ 'role' ],
    computed: {
        imageStyle() {
            return {
                backgroundImage: `url(${this.role.image})`,
            }
        },
    }
}
</script>
<style lang="scss">

.SurveyPopupRole {
    width: 174px;
    cursor: pointer;
        flex: 1;
        .img {
            height: 100px;
            background-size: cover;
        }
        .description {
            font-size: 14px;
            padding: 10px;
            height: 56px;
            background: #e4e0e2;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
}

</style>
