<template>
    <div class="Popover">
        <div class="front">
            <slot />
            <button @keyup.enter="close" @click="close" tabindex="0" class="cross">✖</button>
        </div>
        <div @click="close" class="out"></div>
    </div>
</template>
<script>

let focusListener = null;
export default {
    methods: {
        close() {
            this.$emit('close');
            this.$store.commit('setPopover', null);
        },
    },
    mounted() {
        const input = this.$el.querySelector('input,button');
        input.focus();

        const focusListener = e => {
            if(e.target && e.target.closest && !e.target.closest('.Popover')) {
                input.focus();
            }
        }
        window.addEventListener('focus', focusListener, true);
    },

    beforeUnmount() {
        window.removeEventListener('focus', focusListener, true);
    },

};

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.Popover {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .out {
        z-index: -1;
        position: fixed;
        background: rgba(0,0,0,0.7);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .front {
        position: relative;
        background: #fff;
        width: 540px;
        padding: 30px;
        box-sizing: border-box;
        margin: 100px auto;
    }

    button:first-child {
        margin-left: auto;
    }
    button {
        cursor: pointer;
        margin-left: 10px;
        padding: 8px 16px;
        font-weight: bold;
        font-size: 14px;
        border: 0;
        background: $purple;
        color: white;

        &.cancel {
            background: $darkgray;
        }
    }

    .row {
        display: flex;
        padding: 4px 0;
        &.right {
            margin-left: 160px;
        }
        label {
            display: inline-block;
            width: 160px;
        }
        input:not([type=checkbox]), select {
            padding: 8px;
            flex: 1;
        }

    }

    h2 {
        margin-top: 20px;
    }

    $size: 32px;
    .cross {
        position: absolute;
        right: calc($size / -4);
        top: calc($size / -4);
        width: $size;
        height: $size;

        background: $purple;
        text-align: center;
        color: white;
        border-radius: 50%;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: $phone) {
        .front {
            width: 100%;
            margin: 0;
        }
        .cross {
            top: 10px;
            right: 10px;
        }
    }

}

</style>

