import Vue from 'vue';
import axios from 'axios';

export default new class ConnectStorage {
    constructor() {
        this.vm = new Vue({
            data: {
                obj: null,
            }
        })
    }

    set data(to) {
        this.vm.obj = to;
    }
    get data() {
        return this.vm.obj;
    }

    async setup(connect, course) {
        this.connect = connect;
        this.course = course;
        const data = (await axios.get(this.getStorageUrl())).data;
        this.data = data;
    }

    async save() {
        await axios.put(this.getStorageUrl(), this.data);
    }

    getStorageUrl() {
        const { client, user } = this.connect;
        const program = this.course.id;
        return `/connect/${client}/${user}/${program}`;
    }

    get(key) {
        if(key === 'cmi.core.student_id') { return '1'; }
        if(key === 'cmi.core.student_name') { return 'name'; }
        const { data } = this;
        let result = data[key];
        if(result === undefined) {
            return '';
        }
        return result;
    }

    set(key,val) {
        Vue.set(this.data, key, val);
        this.save();
        return true;
    }

}
