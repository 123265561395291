<template>
    <div class="CourseBar boxed padded">
        <div :class="{ name:1, offset:loggedIn }">{{name}}</div>
        <split-bar right-is-button="true" @click="startClick">
            <template slot="left">
                <course-status v-if="showStatus" :progress="progress" />
            </template>
            <template slot="right">
                {{startTranslated}}
                <svg width="23" height="26">
                    <path fill="white" d="
                    M1.8369701987210297e-16,3
                    L-5.51091059616309e-16,23
                    A3 3 0 0 0 2.598076211353315,24.5
                    L18.401923788646684,14.5
                    A3 3 0 0 0 18.401923788646684,11.5
                    L2.598076211353316,1.4999999999999998
                    A3 3 0 0 0 1.8369701987210297e-16,3
                    "></path>
                </svg>
            </template>
        </split-bar>
    </div>
</template>
<script>

import SplitBar from './SplitBar';
import CourseStatus from './CourseStatus';
import { mapGetters } from 'vuex';

import connectStorage from './connect-storage.js';
export default {
    props: [
        'name',
        'course',
    ],
    components: {
        SplitBar,
        CourseStatus,
    },

    async mounted() {
        const { connect } = this;
        if(connect) {
            await connectStorage.setup(connect, this.course);
        }
    },
    computed: {
        ...mapGetters([
            'loggedIn',
            'courseProgress',
            'user',
            'hash',
            'language',
            'connect',
        ]),
        connectProgress() {
            return connectStorage.data;
        },
        showStatus() {
            const { loggedIn, connect } = this;
            return loggedIn || (connect && connect.stop);
        },

        startTranslated() {
            return this.language === 'ar' ? 'ابدأ' : 'START';
        },
        triangle() {
            const ar = 3;

            var round = (x) => x;

            var angle = (x, y, deg) => {
                const a = deg/180*Math.PI;
                return round(x + ar*Math.cos(a))+','+ round(y + ar*Math.sin(a));
            }
            var arc = (x, y, deg, large, sweep) => {
                return `${ar} ${ar} 0 ${large} ${sweep} ${angle(x,y,deg)}`;
            }

            return `<path fill="white" d="
            M${angle(0,0,90)}
            L${angle(0,26,270)}
            A${arc(0,26,330,0,0)}
            L${angle(21,13,150)}
            A${arc(21,13,210,0,0)}
            L${angle(0,0,30)}
            A${arc(0,0,90,0,0)}
            " />`
        },

        progress() {
            if(this.connect) {
                return this.connectProgress;
            }
            return this.courseProgress(this.course);
        },
    },

    methods: {
        startClick() {
            this.$store.dispatch('startCourse', this.course);
        },
    },
};

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.CourseBar {
    position: relative;
    .name {
        position: absolute;
        top: -0px;
        left: 10px;
        color: $purple;
        font-size: 20px;
        font-weight: bold;
    }
    @media (max-width: $phone) {
        .name {
            top: -20px;
        }
    }

    .offset {
        top: -10px;
        top: -40px;
        left: 100px;
    }
    .left {
        padding-left: 80px;
    }
    .TrafficLight {
        position: absolute;
        left: 10px;
        bottom: 0;
    }
    .right {
        padding: 0;
        svg {
            position: absolute;
            right: 10px;
        }
    }
}

</style>

