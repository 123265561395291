<template>
	<div v-if="page && programRoot" class="PageMyResults PageBasic boxed padded">
        <speech :title="page.title" :body="page.body" />
        <div class="selects">
            <label for="program">Selecteer een programma:</label>
            <select id="program" v-model="filter.program">
                <option :value="null">---</option>
                <option v-for="p in programs" :key="p.name" :value="p.name">{{p.name}}</option>
            </select>
            <label for="state">Ik wil zien:</label>
            <select id="state" v-model="filter.state">
                <option :value="null">---</option>
                <option value="0">nog niet gestart</option>
                <option value="1">bezig</option>
                <option value="2">afgerond</option>
            </select>
        </div>
        <div class="table">
            <div class="bar sorts">
                <span>Programma / onderdeel</span>
                <span>Laatste bezoek</span>
                <span>Status</span>
                <span>Testresultaten</span>
            </div>
            <my-result-row
                      v-for="c in sortedRoot"
                      :key="c.id"
                      :allOpen="allOpen"
                      :node="c" :depth="!filter.program ? 0 : 1" />
        </div>
	</div>
</template>
<script>
import MyResultSort from './MyResultSort';

import axios from 'axios';

import MyResultRow from './MyResultRow';
import Sort from './MyResultSort';
import Speech from './Speech';

import courseProgressInfo from './course-progress-info';
import stats from './stats';

import { mapGetters } from 'vuex';

import langs from './langs';

export default {
    components: {
        Sort,
        MyResultRow,
        Speech,
    },
    data() {
        return {
            filter: {
                program: null,
                state: null,
            },
            lastVisits: {
            },
        };
    },
    async created() {
        const uid = stats.getId();
        this.lastVisits = (await axios.get('/programs/visits/'+uid)).data;
    },

    computed: {
        sortedRoot() {
            return this.root.childs.concat().sort((a,b) => {
                const [aPro, bPro] = [a,b].map(a => a.name === 'Voor professionals');
                if(aPro || bPro) {
                    return aPro ? 1 : -1;
                }
                return a.name < b.name ? -1 : 1;
            });
        },
        allOpen() {
            return this.filter.program !== null || this.filter.state !== null;
        },
        sorts() {
            let result = [];
            Object.entries(this.sortMap).forEach(([key,val]) => {
                if(val !== 0) {
                    result.push({ key, val });
                }
            });
            return result;
        },
        page() {
            return this.content('/pages/mijn-resultaten');
        },
        ...mapGetters([
            'content',
            'programRoot',
            'courseProgress',
            'language',
            'langName',
        ]),

        filterState() {
            if(this.filter.state === null) { return null; }
            return parseInt(this.filter.state);
        },

        root() {
            return this.createRoot();
        },


        programs() {
            const result = []
            this.programRoot.childs.forEach(c => {
                result.push( ...c.childs);
            });
            return result.sort((a,b) => a.name < b.name ? -1 : 1);
        },
    },

    methods: {
        createRoot() {
            let pname = this.filter.program;
            if(pname) {
                const program = this.programs.find(p => p.name === pname);
                let nodes = this.nodes(program);
                return {
                    childs: [ nodes  ],
                }
            }
            return this.nodes(this.programRoot);
        },

        calculateTotal(root) {
            let total = 0;
            let points = 0;
            const recurse = (node) => {
                node.childs.forEach(sub => {
                    if(sub.id) {
                        total++;
                        points += sub.state * 0.5;
                    }
                    if(sub.childs) {
                        recurse(sub);
                    }
                });
            }
            recurse(root);
            return total > 0  ? points/total : 0;
        },

        nodes(program) {
            const result = {
                name: program.name,
            };
            const lang = langs.find(lang => lang.code === this.language).name;
            if(program.scorm || program.scormExternal) {
                result.id = program.id;
                result.scorm = program.scorm;
                result.scormExternal = program.scormExternal;
                result.visit = this.lastVisits[program.id];
                const progress = this.courseProgress(program);

                result.state = courseProgressInfo(progress);
                const score = progress['cmi.core.score.raw'];
                result.score = score !== undefined ? score : '';
                // result.score = Math.round(100*Math.random());
                // result.state = Math.round(2*Math.random());
            } else {
                result.childs = (program.childs || []).map(c => {
                    if(c.childs && c.childs.length && c.childs[0].name === 'Nederlands') {
                        let only = c.childs.filter(c => c.name === lang)[0];
                        if(!only) {
                            only = c.childs[0];
                        }
                        only = this.nodes(only);
                        only.name = this.langName(c);
                        return only;
                    }
                    return this.nodes(c);
                });
                result.totalProgress = this.calculateTotal(result);
                if(this.filterState !== null) {
                    result.childs = result.childs.filter(c => {
                        if(c.state !== undefined) {
                            return c.state === this.filterState;
                        }
                        return true; 
                    });
                    result.childs = result.childs.filter(c => {
                        if(c.childs && c.childs.length === 0) {
                            return false;
                        }
                        return true;
                    });
                }
            }
            return result;
        },
    },
}

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.PageMyResults {
    .speech p {
        margin-bottom: 0;
    }
    select {
        display: block;
        height: 40px;
        width: 240px;
    }

    .selects {
        column-count: 2;
        margin-bottom: 16px;
    }

    .table div {
        margin-bottom: 2px;
    }

    .bar {
        padding: 16px 10px;
        span {
            vertical-align: middle;
        }
        &>span:nth-child(1) {
            width: 50%;
        }
        &>span:nth-child(2) {
            width: 15%;
        }
        &>span:nth-child(3) {
            width: 15%;
        }
    }

    .sorts {
        background: $purple;
        color: white;
        span {
            display: inline-block;
        }

    }

    .theme {
        font-weight: bold;
        background: $green;
        color: white;
    }
}

</style>
