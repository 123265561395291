<template>
    <div class="PageOrganisationsExtra PageBasic">
        <breadcrumbs v-if="!phoneView" :crumbs="crumbs" top="true" />
        <div v-if="page" class="boxed padded">
            <speech :title="page.title" :body="page.body" />
            <program-category-title :cat="cat" />
            <div class="programs">
				<program-tile
					v-for="program in activePrograms"
					:key="program.name"
                    :href="programHref(program)"
					:program="program" />
            </div>
        </div>
        <div class="boxed bottom-back">
            <back-button />
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import DocumentList from './DocumentList';
import Breadcrumbs from './Breadcrumbs';
import Speech from './Speech';
import BackButton from './BackButton';

import ProgramTile from './ProgramTile.vue';
import ProgramCategoryTitle from './ProgramCategoryTitle.vue';
import slug from './slug.js';
export default {
    data() {
        return {
            selected: null,
        };
    },
    components: {
        ProgramCategoryTitle,
        ProgramTile,
        Breadcrumbs,
        DocumentList,
        Speech,
		BackButton,
    },
    methods: {
        programHref(program) {
			return '#/' + [
				'programmas',
                slug(this.professionalCategory.name),
                slug(program.name),
			].join('/');
        },
    },
    computed: {
        page() {
            return this.content('/pages/organisations-pro');
        },
        ...mapGetters([
            'content',
            'phoneView',
			'categories',
            'professionalCourses',
            'professionalCategory',
		]),
        crumbs() {
            if(!this.page) {
                return [];
            }
            return [
                { name:'Voor organisaties', url:'#/voor-organisaties' },
                { name: this.page.title, url:'#/voor-organisaties/pro' },
            ];
        },

        cat() {
            return this.professionalCategory;
        },

        activePrograms() {
            return this.professionalCourses.filter(c => c.enabled && c.enabled.pro);
        },
        
    },
};

</script>
<style lang="scss">

.PageOrganisationsExtra {
    padding-top: 0 !important;
    select {
        width: 320px;
        height: 46px;
        font-size: 16px;
        margin-right: 20px;
        vertical-align: top;
    }
    label {
        display: block;
        padding-bottom: 10px;
    }

    .SearchInput {
        display: inline-block;
    }
    .ProgramCategoryTitle {
        margin-top: 40px;
    }
}

</style>

