<template>
    <popover class="SurveyPopup">
        <speech title="Heb je 2 minuten tijd?" />
        <p>We willen weten wat je van de website en de programma’s vindt. Met jouw antwoorden kunnen we de website verbeteren.</p>
        <strong>Klik hieronder aan wie je bent:</strong>
        <div class="grid">
            <survey-popup-role v-for="(role,i) in roles" :key="i" :role="role" @click.native="roleClick(role)" />
        </div>
        <div class="right">
            <button @click="cancelClick">Nee, bedankt</button>
        </div>
    </popover>
</template>
<script>
import Popover from '../Popover.vue';
import Speech from '../Speech.vue';
import SurveyPopupRole from './SurveyPopupRole.vue';

import roleSelf from '../assets/survey/role-self.jpg';
import roleSupport from '../assets/survey/role-support.jpg';
import roleOther from '../assets/survey/role-other.jpg';
const images = {
    roleSelf,
    roleSupport,
    roleOther,
}

const makeRole = (id,description, url, image) => ({id,description, url, image});
export default {
    components: {
        SurveyPopupRole,
        Speech,
        Popover,
    },

    methods: {
        roleClick(role) {
            window.open(role.url, '_blank');
            this.choiceMade(role.id);
        },

        cancelClick() {
            this.choiceMade('cancel');
        },

        choiceMade(choice) {
            localStorage.setItem('survey-choice', choice);
            this.$store.commit('setPopover', null);
        },

    },

    data() {
        return {
            roles: [
                makeRole(
                    'self',
                    'Ik wil zelf een programma doen',
                    'https://survey.sogosurvey.com/r/SJRC0Y',
                    images.roleSelf),
                makeRole(
                    'support',
                    'Ik ondersteun deelnemers bij het gebruik van deze website',
                    'https://survey.sogosurvey.com/r/ZeyEuJ',
                    images.roleSupport),
                makeRole(
                    'other',
                    'Ik ben op een andere manier betrokken bij de ontwikkeling van mensen',
                    'https://survey.sogosurvey.com/r/mrP56i',
                    images.roleOther),
            ],
        };
    },

}
</script>
<style lang="scss">

@import "../variables.scss";
.SurveyPopup {
    .front {
        width: 620px;
    }
    strong {
        display: block;
        margin-bottom: 20px;
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3,1fr);

        grid-gap: 20px;
        margin-bottom: 30px;
    }
    @media (max-width: $phone) {
        .front {
            width: auto;
        }

        .grid {
            grid-template-columns: 1fr 1fr;
        }
    }
    @media (max-width: 400px) {
        .grid {
            grid-template-columns: 1fr;
        }
    }

    .right {
        display: flex;
        justify-content: flex-end;
    }
}

</style>
