export default (root, target) => {
    let found = null;
    const recurse = (node, chain) => {
        let clone = [ ...chain, node.name ];
        if(Array.isArray(node.childs)) {
            node.childs.forEach(sub => {
                if(sub.id === target) {
                    found = {
                        course:sub,
                        parent:node,
                        chain: clone.slice(2),
                    };
                } else if(Array.isArray(sub.childs)) {
                    recurse(sub, clone);
                }
            });
        }
    }
    recurse(root, []);
    return found;
};
