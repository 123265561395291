import getChapterAndModuleIds from './get-chapter-and-module-ids';
import oefenenUrl from './oefenen-url';
import axios from 'axios';
import store from './store';

export default async(userKey, course, data, force) => {
    const { chapterId, moduleId } = getChapterAndModuleIds(course);

    let score = data['cmi.core.score.raw'];
    if(score === undefined) {
        score = data['cmi.score.raw'];
        if(score === undefined) {
            if(force) {
                score = 100;
            } else {
                return;
            }
        }
    }

    const total = 1;
    const done = 1;
    const correct = 1;

    const payload = `
    <?xml version="1.0" ?>
<Results>
<Result>
<UserKey>${userKey}</UserKey>
<ChapterID>${chapterId}</ChapterID>
<ModuleID>${moduleId}</ModuleID>
<ExerciseTotal>${total}</ExerciseTotal>
<ExerciseDone>${done}</ExerciseDone>
<ExerciseCorrect>${correct}</ExerciseCorrect>
 </Result>
</Results>`;

    const url = oefenenUrl(course, 'Results');
    axios.post(url, payload);
    
}
