import courseProgressInfo from './course-progress-info';

import stats from './stats';
import store from './store';

export default class SCORMAPI {
    constructor(course, storage) {
        this.course = course;
        this.storage = storage;
    }
	Initialize() {
        this.initTime = new Date();
        this.SetValue('started', 1);
        stats.add('course.init', { course:this.getCourseId() });
        return 'true';
	}

	Terminate() {
        const now = new Date();
        try {
            this.win.close();
        } catch(e) {
            //probably closed already
        }

        const secondsElapsed = Math.round( (now.getTime() - this.initTime.getTime())/1000 );
        stats.add('course.terminate', { course:this.getCourseId(), sessionTime:secondsElapsed });
	}

    getCourseId() {
        return this.course.id;
    }

	GetValue(key) {
        return this.storage.get(key) || '';
	}

	SetValue(key,val) {
        if(['cmi.completion_status', 'cmi.core.lesson_status'].includes(key)) {
            const obj = {};
            obj[key] = val;
            if(courseProgressInfo(obj) === 2) {
                stats.add('course.finish', { course:this.getCourseId() });
            }
        }
        return  this.storage.set(key, val);
	}

	Commit() {
        return 'true';
	}

	GetLastError() {
        return 0;
	}

	GetErrorString() {
	}

	GetDiagnostic() {
	}
    LMSInitialize() { return this.Initialize(); }
    LMSFinish() { return this.Terminate(); }
    LMSGetValue(key) { return this.GetValue(key); }
	LMSSetValue(key,val) { return this.SetValue(key,val); }
	LMSCommit() { return this.Commit(); }
	LMSGetLastError() { return this.GetLastError(); }
	LMSGetErrorString() { return this.GetErrorString(); }
	LMSGetDiagnostic() { return this.GetDiagnostic(); }
}
