
import nanoid from 'nanoid'
import axios from 'axios'

class Stats {
    constructor() {
        this.source = null;
        this.loggedIn = false;
    }

    setLoggedIn(to) {
        this.loggedIn = to;
    }

    add( name, info ) {
        const { source } = this;
        const uid = this.getId();
        const date = new Date();
        axios.post('/stats', {
            uid,
            date,
            name,
            info,
            loggedIn:this.loggedIn,
            ...(source ? { source } : {}),
        });
    }

    setId(to) {
        this._id = to;
    }

    getId() {
        if(this._id) { return this._id; }
        let result = localStorage.getItem('uid');
        if(!result) {
            result = nanoid(10);
            localStorage.setItem('uid', result);
        }
        this._id = result;
        return result;
    }
};

export default new Stats;
