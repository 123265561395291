<template>
    <span class="MyResultSort">
        <slot />
    </span>
</template>
<script>

export default {
    props: [ 'value' ],
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(to) {
                this.$emit('input', to);
            }
        },
    },
};

</script>
<style lang="scss">

.MyResultSort {
    user-select: none;
}

</style>
