<template>
	<div class="PageProgram" v-if="program" :lang="language">
		<header v-if="!oefenen">
			<div class="boxed padded">
            <back-button v-if="!phoneView" />
                <div v-if="program.youtube" class="youtube">
                    <youtube :src="langYoutube" :size="youtubeSize" width="500" height="280" />
                </div>
                <div v-else class="slideshow">
                    <img v-if="image" width="500" :src="image" alt="slideshow" />
                </div>
			</div>
		</header>

        <breadcrumbs v-if="!phoneView" :crumbs="crumbs" />
        <div v-else class="border" />

        <div v-if="showSmallLanguage(program)" class="boxed padded language">
            <languages class="small" @change="langChange" />
        </div>

		<div class="split boxed padded">
            <div class="left">
                <speech :title="langName(program)" :body="langIntro" :key="program.id" />
            </div>
            <div v-if="!phoneView && program.images" class="right">
                <img v-for="(image,i) in selectedImages" :key="i"
                     width="280"
                     :src="image" alt="Preview afbeelding voor cursus" />
            </div>
		</div>
        <course-bar v-if="singleProgram" :course="singleProgram" />
        <div v-else class="subs boxed padded">
            <template v-if="immediate">
                <div v-for="subcat in subcats" :key="subcat.name">
                    <program-category-title :cat="subcat" class="sub" />
                    <course-bar
                        v-for="sub in subcat.childs" :key="sub.name"
                        class="stacked"
                        :name="langName(sub)"
                        :course="sub"
                        />
                </div>
            </template>
            <template v-else>
                <subs-intro-bar :title="langName(program)" />
                <program-tile
                    v-for="sub in program.childs"
                    :key="sub.name"
                    @click.native="programClick(sub)"
                    :program="sub"
                    />
            </template>
        </div>
        <div v-if="!oefenen" class="boxed bottom-back">
            <back-button />
        </div>
        <pick-language v-if="showPickLanguage" @close="closePopover" />
	</div>
</template>
<script>
import SubsIntroBar from './SubsIntroBar';

import ProgramCategoryTitle from './ProgramCategoryTitle';
import Breadcrumbs from './Breadcrumbs';
import ProgramTile from './ProgramTile';
import CourseBar from './CourseBar';
import BackButton from './BackButton';
import Speech from './Speech';
import Youtube from './Youtube';

import PickLanguage from './PickLanguage';
import Languages from './Languages';


import { mapGetters } from 'vuex';

import slug from './slug';
import langs from './langs';

export default {
	components: {
        PickLanguage,
        SubsIntroBar,
        ProgramCategoryTitle,
        Breadcrumbs,
        ProgramTile,
        CourseBar,
		BackButton,
        Speech,
        Youtube,
        Languages,
	},
    data() {
        return {
            showPickLanguage: false,
        };
    },

    watch: {
        program() {
            this.tryLanguage();
        },

        hash() {
            this.tryLanguage();
        },
    },
    mounted() {
        this.tryLanguage();
    },


	computed: {

        langYoutube() {
            return this.program[this.language+'_youtube'] || this.program.youtube;
        },
        langIntro() {
            return this.program[this.language+'_intro'] || this.program.intro;
        },
        youtubeSize() {
            if(!this.phoneView) {
                return { width:500, height:280 };
            }
            return { width:this.width, height:212 };
        },
        selectedImages() {
            const arr = this.program.images;
            if(this.program.youtube) {
                return arr;
            }
            return arr.slice(1);
        },
        image() {
            const arr = this.program.images;
            if(arr && arr.length > 0) {
                return arr[0];
            }
            return null;
        },

        singleProgram() {
            let {program} = this;
            if(program.scorm) {
                return program;
            }
            if(program.scormExternal) {
                return program;
            }
            if(this.isLanguageParent(program)) {
                let code = this.language;
                let name = langs.find(lang => lang.code === code).name
                return program.childs.find(p => p.name === name);
            }
            return null;
        },
		...mapGetters([
            'user',
            'language',
            'chosenLanguage',
            'isLanguageParent',
            'langName',
			'programRoot',
			'program',
            'pageHash',
            'pageHashParts',
            'hash',
            'phoneView',
            'width',
            'oefenen',
		]),


        immediate() {
            return (
                this.program.scorm == undefined &&
                this.program.scormExternal == undefined &&
                this.program.subtiles !== true
            );
        },


        subcats() {
            if(this.program.childs.length && this.program.childs[0].scorm) {
                return [ this.program ];
            }
            return this.program.childs;
        },

        crumbs() {
			const parts = this.pageHashParts;
            if(parts.length <= 3) {
                return null;
            }

			let node = this.getNodeChild(this.programRoot, parts[1]);
            return parts.slice(2).map( (part,i) => {
                node = this.getNodeChild(node, part);
                return {
                    name:node ? this.langName(node) : 'anonymous',
                    url:'#/'+parts.slice(0,3+i).join('/')
                };
            });
        },
	},
    methods: {
        showSmallLanguage(program) {
            if(this.isLanguageParent(program)) { return true; }
            if(program.childs && program.childs.length) {
                if(this.isLanguageParent(program.childs[0])) { return true; }
            }
            return false;
        },

        langChange(lang) {
            this.$store.dispatch('setLanguage', lang.code );
        },
        tryLanguage() {
            try {
                const hasLangChild = (n) => n.childs[0].name === 'Nederlands';
                if(hasLangChild(this.program) || hasLangChild(this.program.childs[0])) {
                    if(!this.chosenLanguage) {
                        this.showPickLanguage = true;
                    }
                }
            } catch(e) {
            }
        },
        closePopover() {
            this.showPickLanguage = false;
        },
        getNodeChild(p, needle) {
            return p.childs.find( c => slug(c.name) === needle );

        },
        programClick(sub) {
            location.href = this.hash + '/' + slug(sub.name);
        },
    },
}

</script>
<style lang="scss">

@import "colors.scss";

@import "variables.scss";

.PageProgram {
	header {
		background: $lightgray;
		background: url('./assets/SBCM_header_bg.png');
        background-color: #5A2E7B;
        background-size: cover;
		height: 354px;
		.boxed {
			display: flex;
			justify-content: center;
			align-items: center;
		}

	}
    @media (max-width: $phone) {
        header {
            margin-top: -10px;
            height: 222px;
            overflow: hidden;
        }
        .subs {
            text-align: center;
        }

        .stacked {
            margin-top: 70px;
            margin-bottom: 70px;
        }
    }
    .split {
        display: flex;
        .left {
            flex: 1;
        }
        .right {
            img {
                display: block;
                margin-bottom: 20px;
            }
        }
    }


    .border {
        height: 5px;
        background: $purple;
    }

    .language {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.rtl .PageProgram {
    .Languages {
        direction: rtl;
    }

    .Speech {
        direction: rtl;
    }
    .left, .right {
        direction: rtl;
        padding-left: 0;
        padding-right: 16px;
    }
    .right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

}



</style>
