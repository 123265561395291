<template>
	<main id="main" class="Center">
		<component :is="page" />
	</main>
</template>
<script>

import { mapGetters } from 'vuex';

import PageHome from './PageHome';
import PageContact from './PageContact';
import PageOrganisations from './PageOrganisations';
import PageOrganisationsAbout from './PageOrganisationsAbout';
import PageOrganisationsExtra from './PageOrganisationsExtra';
import PageOrganisationsPro from './PageOrganisationsPro';
import PageFaq from './PageFaq';
import PageMyResults from './PageMyResults';
import PageBasic from './PageBasic';
import PageProgram from './PageProgram'
import PageActivate from './PageActivate'
import PageReset from './PageReset'

import PageSitemap from './PageSitemap.vue';
export default {
    components: {
        PageSitemap,
    },
	computed: {
		...mapGetters([
			'pageHash',
		]),
		page() {
            let hash = this.pageHash;
            const map = {
                'programmas': PageHome,
                'voor-organisaties': PageOrganisations,
                'voor-organisaties/over': PageOrganisationsAbout,
                'voor-organisaties/extra': PageOrganisationsExtra,
                'voor-organisaties/pro': PageOrganisationsPro,
                'vraag-en-antwoord': PageFaq,
                'mijn-resultaten': PageMyResults,
                'contact': PageContact,
                'sitemap': PageSitemap,
            }
            let page = map[ hash ];
            if(page) {
                return page;
            }
            const parts = hash.split('/');
			switch(parts[0]) {
                case 'activate':
                    return PageActivate;
                case 'reset':
                    return PageReset;
				case 'programmas': 
					return this.programPage(parts);
                case 'vraag-en-antwoord':
                    return PageFaq;
			}
			return PageBasic;
		},
	},

	methods: {
		programPage(parts) {
			if(parts.length < 2) {
				return PageBasic;
			}
			return PageProgram;
		},
	},
}

</script>
<style lang="scss">

.Center {
    &.normal {
        padding-top: 70px;
        @media all and (max-width: 800px) {
            & {
                padding-top: 48px;
            }
        }
    }
	min-height: calc(100vh - 192px);
}

</style>
