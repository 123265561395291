<template>
    <h2 class="ProgramCategoryTitle">{{langName(cat)}}</h2>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
    props: [
        'cat',
    ],

    computed: {
        ...mapGetters([
            'langName',
        ]),
    },
};

</script>
<style lang="scss">

@import "colors.scss";

.ProgramCategoryTitle {
    font-size: 16px;
    padding: 10px;
    background: $green;
    color: white;
    border-top-right-radius: 20px;

    box-sizing: border-box;
    &.sub {
        background: $darkgray;
        margin-bottom: 50px;
    }


    
}

</style>
