<template>
    <div class="CourseStatus">
        <traffic-light :color="color" />
        <div class="text" v-html="result" />
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import TrafficLight from './TrafficLight';

import courseProgressInfo from './course-progress-info';

const stateTranslations = {
    nl: [
        'Je hebt dit onderdeel nog niet gestart.',
        'Je hebt dit onderdeel nog niet afgerond.',
        'Afgerond',
        'Afgerond, <b>jouw toetsresultaat is:</b> [SCORE]%',
    ],
    en: [
        'You have not started with this part yet.',
        'You have not yet completed this part.',
        'Completed',
        'Completed, <b>your test result is:</b> [SCORE]%',
    ],
    ar: [
        'أنت لم تبدأ هذا القسم بعد.',
        'لم تنتهي من هذا القسم بعد.',
		'تم الانتهاء',
        'تم الانتهاء، ونتيجة الاختبار هي: [SCORE]٪',
    ],
};

export default {
    props: [
        'progress',
    ],
    components: {
        TrafficLight,
    },
    computed: {
        ...mapGetters([
            'language',
        ]),

        progressStatus() {
            const p = this.progress;
            if(!p) { return 0; }
            return courseProgressInfo(p);
        },

        color() {
            return [ 'red', 'yellow', 'green' ][this.progressStatus];
        },

        result() {
            let arr = stateTranslations[this.language];
            let str = arr[ this.progressStatus ];
            if(this.progressStatus === 2) {
                let score = this.progress['cmi.core.score.raw'];
                if(score !== undefined) {
					str = arr[3].replace('[SCORE]', score);
                } else {
                    str += '.';
                }
            }
            return str;
        },
    },
};

</script>
<style lang="scss">

.CourseStatus {
}

</style>

