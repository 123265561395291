<template>
    <span class="MyResultState">
        <span class="circle"
            v-for="state in states" :key="state"
            :style="{ background: colors[state] }"
            :class="{ white: !active(state) }"
            />
    </span>
</template>
<script>

import courseProgressInfo from './course-progress-info';

export default {
    props: [
        'node',
    ],

    data() {
        return {
            colors: [
                '#E8000A',
                '#FF9F00',
                '#7EC034',
            ],
        };
    },

    methods: {
        active(state) {
            return state === this.nodeStatus;
        },
    },
    computed: {
        nodeStatus() {
            return this.states[this.node.state];
        },

        states() {
            return [ 0, 1, 2 ];
        },
    },
};

</script>
<style lang="scss">

.MyResultState {
    .circle {
        display: inline-block;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border: 1px solid #eee;
        border-radius: 50%;
        background: white;
    }

    .white {
        background: white !important;
    }
    .active {
        &.red {
            background: #E8000A;
        }
        &.yellow {
            background: #FF9F00;
        }
        &.green {
            background: #7EC034;
        }
    }

}

</style>
