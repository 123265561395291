<template>
	<div class="Top">
        <div class="boxed">
			<a href="#/" aria-label="Home">
                <img :class="logoClass" width="230" height="80" src="./assets/logo_werk-portal.svg" alt="Het logo van de WERK-portal.nl. Er staat: WERK-portal.nl met daaronder Leren voor je werk." />
			</a>
			<nav :class="navClass" id="nav">
                <a v-if="!phoneView" tabindex="0" class="skip-link"
                    @click="skipToContent"
                    @keyup.enter="skipToContent"
                  >direct naar content</a>
				<a v-for="link in links" :key="link.name"
					:class="{ active:linkActive(link) }"
                    :href="'#/'+link.slug">
					{{link.name}}
				</a>
                <a v-if="!user" class="user" href="#/login" aria-label="Inloggen">
					<icon name="user" scale="1.1" />
					Inloggen
				</a>
                <span v-else class="user"
                    tabindex="0"
                    @click="showProfile"
                    @keyup.enter="showProfile"
                >
					<icon name="user" scale="1.1" />
                    {{user.firstname}}
                    |
                    <a
                        tabindex="0"
                        @click.stop="logoutClick"
                        @keyup.enter.stop="logoutClick"
                        aria-label="Uitloggen"
                        class="link">uitloggen</a>
                </span>
			</nav>
            <button v-if="phoneView"
                class="grabber"
                tabindex="0"
                @click="toggleMenu"
                @keyup.enter="toggleMenu"
                aria-controls="nav"
                :aria-expanded="(!menuHidden).toString()">
                <div />
                <div />
                <div />
            </button>
		</div>
	</div>
</template>
<script>

import 'vue-awesome/icons/user'
import { mapGetters } from 'vuex';

class Link {
	constructor(name, slug) {
		this.name = name;
		this.slug = slug;
	}
}
	

export default {
    created() {
        window.onscroll = ()=> {
            this.onScroll();
        }
    },

	data() { return {
        scrollY: 0,
        menuHidden: true,
	}},

	methods: {
        skipToContent() {
            let element = document.querySelector('main button, main input, main a');

			if(element)
				element.focus();

            this.menuHidden = true;
        },

        toggleMenu() {
            this.menuHidden = !this.menuHidden;
        },
        showProfile() {
            this.$store.commit('setPopover', 'Profile');
        },
        onScroll() {
            this.scrollY = window.pageYOffset;
        },

        login() {
            this.$store.commit('setPopover', 'Login');
        },
        logoutClick(e) {
            e.stopImmediatePropagation();
            e.preventDefault();
            this.$store.dispatch('logout');
        },
		linkActive(link) {
			return this.hash && this.hash.indexOf('#/'+link.slug) === 0;
		}
	},

    watch: {
        hash() {
            this.menuHidden = true;
        }
    },

	computed: {
		links() {
            const result = [
                new Link('Programma\'s', 'programmas'),
                new Link('Voor organisaties', 'voor-organisaties'),
                new Link('Vraag en antwoord', 'vraag-en-antwoord/algemeen'),
            ];

            if(this.user) {
                if(this.phoneView || this.width > 1000) {
                    result.push( new Link('Mijn resultaten', 'mijn-resultaten') );
                }
            }
            return result;
		},
        navClass() {
            let hidden = false;
            if(this.phoneView && this.menuHidden) {
                hidden = true;
            }
            return {
                hidden,
            }

        },
        logoClass() {
            if(this.scrollY==0) {
                return {};
            }
            return {
                small:true,
            }
        },
        loginLink() {
            if(this.hash === '#/logout') {
                return '/#/programmas/login';
            }
            return this.hash + '/login';
        },

		...mapGetters([
			'hash',
			'user',
            'phoneView',
            'width',
		])
	},
}

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.Top {
    @media (max-width: $phone) {
        & {
            height:48px;
        }
    }
	position: fixed;
	z-index: 1;
	height: 70px;
	width: 100%;
	background: white;
	box-shadow: 0 0 20px rgba(0,0,0,0.4);


	.boxed {
		height: 70px;
	}

	img {
        width: 230px;
        height: 80px;
		margin-left: 10px;
		margin-top: 9px;
        transform-origin: top left;
        transition: transform 0.2s;
        &.small {
            transform: scale(0.6);
        }
        @media (max-width: $phone) {
            width: 138px;
            height: 48px;
        }
	}

    .link {
        text-decoration: underline;
    }
    nav>a {
        text-decoration: none;
        color: #666;
        &.active {
            font-weight: bold;
            color: #992A9D;
        }
    }

    @media (min-width: $desk) {
        nav {
            position: absolute;
            right: 14px;
            color: #666;
            top: 28px;
            cursor: pointer;
        }

        nav>* {
            margin-left: 40px;
        }


    }

    @media (max-width: $phone) {
        nav {
            background: $lighterpurple;
            position: absolute;
            padding: 20px 0;
            top: 48px;
            height: 212px;
            box-sizing: border-box;
            left: 0;
            right: 0;
        }
        nav>a {
            display: block;
            padding: 8px 24px;
            border-bottom: 1px solid rgba($purple,0.1);
        }

        .user {
            position: absolute;
            right: 70px;
            top: -34px;
            padding: 0;
            border-bottom: 0;
        }

        .hidden {
            background: none;
            height: 0;
        }

        .hidden>* {
            display: none;
        }
        .hidden .user {
            display: block;
        }

        .grabber {
            border: 0;
            cursor: pointer;
            width: 48px;
            height: 48px;
            position: absolute;
            right: 0;
            top: 0;
            background: $lighterpurple;
            padding: 11px 10px;
            box-sizing: border-box;
            div {
                height: 5px;
                background: $purple;
                margin-bottom: 5px;
            }
            .x {
                user-select: none;
                position: relative;
                left: 10px;
                top: 8px;
                font-weight: bold;
                color: $purple;

            }
        }

    }
    .skip-link {
        opacity: 0;
        &:focus {
            opacity: 1;
        }
    }

}


</style>

