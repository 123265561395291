import axios from 'axios';
import store from './store';

export default class UserStorage {
    constructor(course) {
        this.course = course;
    }
    get(key) {
        if(key === 'cmi.core.student_id') { return '1'; }
        if(key === 'cmi.core.student_name') { return 'name'; }

        try {
            let result = store.getters.courseProgress( this.course )[key];
            if(result === undefined) {
                return '';
            }
            return result;
        } catch(e) {
            return null;
        }
    }

    set(key,val) {
        store.dispatch('updateCourse', {
            c:this.course,
            e:'set',
            key,
            val,
        });
        return 'true';
    }
}
