export default (p) => {
    let completionStatus = p['cmi.completion_status'];
    if(completionStatus !== undefined) {
        if(completionStatus === 'completed') {
            return 2;
        }
        return 1;
    }

    let lessonStatus = p['cmi.core.lesson_status'];
    if(lessonStatus !== undefined) {
        if(['passed', 'completed', 'failed'].indexOf(lessonStatus) !== -1) {
            return 2;
        }
        return 1;
    }
    if(p['started'] !== undefined) {
        return 1;
    }
    return 0;

}
