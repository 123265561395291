<template>
	<div class="HomeWelcome">
		<div class="image" role="img" aria-label="Een foto van een lachende vrouw met een schort aan. Ze heeft een stapel borden vast. Naast haar zit een lachende man achter een laptop.">
            <div class="boxed padded">
                <div class="computer" />
                <div class="borden" />
            </div>
        </div>
        <div class="welcome">
            <speech v-if="page"
                class="boxed padded"
                :title="page.title"
                :body="loggedIn ? page.loggedinBody : page.body"
                :personalTitle="loggedIn ? 'Welkom '+user.firstname : null"
                :replaceBody="injectInstructionVideoLink"
                />
        </div>
	</div>
</template>
<script>

import Speech from './Speech'
import { mapGetters } from 'vuex';

import transcript from './assets/transcript.png';

export default {
    data() { return {
        iconData: `<svg aria-label="Bekijk video" aria-inline="true" width="32" height="28.444444444444443" viewBox="0 0 576 512" focusable="false" class="fa-icon" style="font-size: 2em;"><path d="M549.7 124.1c11.4 42.9 11.4 132.3 11.4 132.3s0 89.4-11.4 132.3c-6.3 23.6-24.8 41.5-48.3 47.8-42.6 11.5-213.4 11.5-213.4 11.5s-170.8 0-213.4-11.5c-23.5-6.3-42-24.2-48.3-47.8-11.4-42.9-11.4-132.3-11.4-132.3s0-89.4 11.4-132.3c6.3-23.7 24.8-42.3 48.3-48.6 42.6-11.5 213.4-11.5 213.4-11.5s170.8 0 213.4 11.5c23.5 6.3 42 24.9 48.3 48.6zM232.1 337.6l142.7-81.2-142.7-81.2v162.4z"></path></svg>`,
    }},
	components: {
		Speech,
	},
	computed: {
        page() {
            return this.content('/pages/programs');
        },
		...mapGetters([
			'content',
			'loggedIn',
			'user',
			'phoneView',
		]),
	},

    methods: {
        injectInstructionVideoLink(str) {
            const lines = str.split('<br/><br/>');
            lines.splice(lines.length-3, 0, `
            <a class="video-link" href="#/programmas/instruction-video" aria-label="de video over de voordelen van een inlog.">
                ${this.iconData}Bekijk de video over de voordelen van een inlog.
                </a>
            <a class="transcript-link" href="#/transcript-hoe-werkt-het" aria-label="transcript video over de voordelen van een inlog.">
                <svg width="30" height="30" viewBox="-4 0 30 30">
                    <rect width="24" height="30" fill="#8d4198" rx="4" ry="4"/>
                    <rect x="-1" y="-1" width="10" height="10" fill="#8d4198" stroke="#E9E7E8" rx="2" ry="2"/>
                <path d="M-3,-3L10,0 0,10z" fill="#E9E7E8" />
                <g stroke="white" transform="translate(0,0.2)">
                <path d="M12,5l8,0" />
                <path d="M12,8l8,0" />
                <path d="M4,12l16,0" />
                <path d="M4,16l16,0" />
                <path d="M4,20l16,0" />
                <path d="M4,24l16,0" />
                </g>
                </svg>
                Lees een uitgeschreven versie van de video (transcript).
            </a>
            `);
            return lines.join('<br/><br/>');
        }
    },
}

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.HomeWelcome {
    svg {
        vertical-align: middle;
        margin-right: 4px;
        color: $purple;
    }
    &>svg {
        display: none;
    }
    position: relative;
	.image {
		height: 354px;
        .boxed {
		height: 354px;
        }
		background: url('./assets/SBCM_header_bg.png');
        background-color: #5A2E7B;
        background-size: cover;
		border-bottom: 4px solid $purple;
        overflow: hidden;
	}

    .borden, .computer {
        background-size: contain;
        position: absolute;
    }

    .borden {
        width: 321px;
        height: 432px;
        left: -60px;
        background-image: url('./assets/borden.png');
    }
    .computer {
        left: 100px;
        width: 773px;
        height: 300px;
        top: 70px;
        background-image: url('./assets/computer.png');
    }
    @media (max-width: $phone) {
        .image {
            height: 212px;
            height: 146px;
        }
        .borden {
            width: 120px;
            height: 216px;
            left: 10px;
            top: 24px;
        }
        .computer {
            width: 289px;
            height: 150px;
            left: 60px;
            top: 34px;
        }
    }

	h1 {
		color: $darkgray;

	}

    .video-link, .transcript-link {
        display: block;
        img {
        }
        .icon {
            vertical-align: middle;
        }
    }

	.welcome {
		color: #5D5D5D;
		background: $lighterpurple;
		min-height: 240px;
        @media (min-width: $desk) {
            .p {
                column-count: 2;
                column-gap: 120px;
            }
        }
	}
}

</style>
