<template>
    <popover class="ContinueCourse" v-if="programRoot" @close="close">
        <h1>Verder waar je gebleven was</h1>
        <p>Je hebt de laatste keer geoefend met het programma <b>{{name}}</b>.</p>
        <p>Wil je verder gaan waar je gebleven was?</p>
        <div class="row">
            <button class="cancel" @click="cancelClick">Nee</button>
            <button class="ok" @click="confirmClick">Ja</button>
        </div>
    </popover>
</template>
<script>


import { mapGetters } from 'vuex';
import Popover from './Popover';
import openScorm from './open-scorm';

import courseAndParent from './course-and-parent';
export default {
    components: {
        Popover,
    },
    methods: {
        cancelClick() {
            this.close();
        },

        confirmClick() {
            openScorm(this.course);
            this.close();
        },
        close() {
            this.$store.commit('setPopover', null);
        }
    },
    computed: {
        name() {
            let { course, parent, chain } = this.courseAndParent;
            if(!course) { return ''; }
            if(![ 'Nederlands', 'Makkelijk' ].includes(parent.childs[0].name)) {
                chain.push(
                    course.name,
                );
            }
            if(chain.length === 1) {
                return chain[chain.length-1];
            }
            return chain[0] + ', '+chain[chain.length-1].replace(/[0-9]+\) /,'')

        },
        course() {
            return this.courseAndParent.course;
        },

        courseAndParent() {
            return courseAndParent(this.programRoot, this.user.lastCourse);
        },

        ...mapGetters([
            'user',
            'programRoot',
        ]),
    },
};

</script>

<style lang="scss">

@import "./colors.scss";
@import "./variables.scss";

.PickLanguage {
    .langs {
        margin-top: 20px;
        img {
            width: 100px;
            display: block;
            margin-bottom: 4px;
            cursor: pointer;
            box-sizing: border-box;
        }
        .active img {
            border: 4px solid $purple;
        }

        div {
            display: inline-block;
            text-align: center;
            margin-right: 80px;
        }
        div:last-child {
            margin-right: 0px;
        }
        @media (max-width: $phone) {
            div {
                margin-right: 40px;
            }
        }
    }
    .row {
        margin-top: 20px;
    }
}

</style>

