export default (code) => {
    switch(code) {
        case 'email.exists': return 'Dit e-mailadres is al geregistreerd.';
        case 'email.invalid': return 'Dit e-mailadres voldoet niet aan de eisen.';
        case 'password.nomatch': return 'Wachtwoorden komen niet overeen.';
    }
    const minMatch = code.match(/password\.min\.([0-9]+)/);
    if(minMatch) {
        return 'Wachtwoord bevat minimaal ' + minMatch[1] + ' karakters.';
    }
    return 'Onbekende fout: '+code;
}
