<template>
    <popover class="Register" v-if="page">
        <template v-if="!registered">
            <h1>{{page.title}}</h1>
            <p v-html="page.body" />
            <div v-for="f in fields" class="row">
                <label :for="f.name">{{f.label}}</label>
                <input :id="f.name" :name="f.name" :autocomplete="f.auto" v-model="input[f.name]" :type="f.type" />
            </div>

			<input ref="newPassword" autocomplete="new-password" type="email" id="email" name="email" placeholder="Your e-mail here" style="position:absolute;top:-9999px;left:-9999px;">

            <div role="alert">
                <div v-if="registerError" class="error">{{registerError}}</div>
            </div>

            <div class="row right">
                <label for="agree-to-terms">
                    <input type="checkbox" id="agree-to-terms" v-model="input.agreedTerms" />
                    <a target="_blank" href="/#/privacy" aria-label="Ga naar de privacyverklaring">Ik ga akkoord met de privacyverklaring</a>
                </label>
            </div>
            <div class="row right">
                <label for="newsletter">
                    <input type="checkbox" id="newsletter" v-model="input.newsletter" />Ja, ik meld mij aan voor de nieuwsbrief
                </label>
            </div>
            <div class="row">
                <button class="cancel" @click="cancelClick" aria-label="Stoppen">Stoppen</button>
                <button @click="registerClick" aria-label="Inschrijven">Inschrijven</button>
            </div>
        </template>
        <template v-else>
            <h1>Je aanmelding is gelukt!</h1>
            <p>Je hebt een e-mail ontvangen met een link om te kunnen starten.</p>
        </template>
    </popover>
</template>
<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';

import Popover from './Popover';

const field = (label,name,auto=null,type='text') => ({ label, name, auto, type });

export default {
    components: {
        Popover,
    },
    data() {
        return {
            fields: [
                field('Voornaam', 'firstname', 'given-name'),
                field('Achternaam', 'lastname', 'family-name'),
                field('E-mailadres', 'email', 'email'),
                field('Wachtwoord\n(minimaal 6 karakters)', 'password', 'new-password', 'password'),
                field('Wachtwoord herhalen', 'password2', 'new-password', 'password'),
            ],
            input: {
                agreedTerms: false,
                newsletter: false,
            },
        };
    },

    created() {
        this.$store.commit('setRegisterError', null);
        this.fields.forEach( f => Vue.set(this.input, f.name, null) );
    },
    methods: {
        registerClick(){
            this.$store.dispatch('register', {
				input: {
					...this.input,
					newPassword: this.$refs['newPassword'].value
				},
				fields: this.fields
			});

        },
        cancelClick() {
            this.$children[0].close();
        }
    },
    computed: {
        ...mapGetters([
            'registered',
            'registerError',
            'content',
        ]),
        page() {
            return this.content('/pages/register');
        },
    },
};

</script>

<style lang="scss">

.Register {
    label {
        white-space: pre-wrap;
    }
    .row.right label {
        cursor: pointer;
        width: auto;
    }
}

</style>

