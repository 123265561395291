<template>
    <split-bar class="SubsIntroBar">
        <div slot="left">
            <h2>{{title}}</h2>
        </div>
        <div slot="right">
            {{hint}}
            <div class="arrow">
                <icon name="arrow-down" scale="3.5" />
            </div>
        </div>

    </split-bar>
</template>
<script>

import { mapGetters } from 'vuex';
import 'vue-awesome/icons/arrow-down';
import SplitBar from './SplitBar';


export default {
    props: [ 'title' ],
    components: {
        SplitBar,
    },

    computed: {
        hint() {
            return {
                nl: 'Kies hieronder een module',
                en: 'Choose a module below',
                ar: 'اختر وحدة أدناه',
            }[this.language];
        },

        ...mapGetters([
            'language',
        ]),
    },



};

</script>
<style lang="scss">

@import "colors.scss";

.SubsIntroBar {
    margin-bottom: 40px;

    .arrow {
        $size: 80px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        background: $purple;
        position: absolute;
        top: -16px;
        right: -4px;
    }
}

.rtl .SubsIntroBar {
    .arrow {
        right: auto;
        left: -8px;
    }
}

</style>
