<template>
    <popover class="Login" v-if="page">
        <h1>{{page.title}}</h1>
        <p v-html="page.body" />
        <form @submit.prevent="login">
            <div class="row">
                <label for="email">E-mailadres</label>
                <input id="email" name="email" autocomplete="email" v-model="email" />
            </div>
            <div class="row">
                <label for="password">Wachtwoord</label>
                <input id="password" name="password" autocomplete="current-password" v-model="password" type="password" />
            </div>
            <div class="row">
                <button aria-label="Login">Login</button>
            </div>
        </form>

        <div role="alert">
            <div v-if="loginError" class="error">{{loginError}}</div>
        </div>

        <div class="row">
            <span class="link" @click="forgot" aria-label="Wachtwoord vergeten?">Wachtwoord vergeten?</span>
        </div>
        <div class="row">
            Nog geen inlog?&nbsp;<span class="link" @click="register" aria-label="Maak deze hier gratis aan.">Maak deze hier gratis aan.</span>
        </div>
        <h2>{{page.skipTitle}}</h2>
        <p v-html="page.skipBody" />
        <div class="row">
            <button class="cancel" @click="cancelClick" aria-label="Verder zonder inloggen">{{page.skipButton}}</button>
        </div>
    </popover>
</template>
<script>

import { mapGetters } from 'vuex';
import Popover from './Popover';

export default {
    components: {
        Popover,
    },
    data() {
        return {
            email: null,
            password: null,
        };
    },
    created() {
        this.$store.commit('setLoginError', null);
    },
    methods: {
        register() {
            this.$store.commit('setPopover', 'Register');
        },
        forgot() {
            this.$store.commit('setPopover', 'Forgot');
        },
        login() {
            this.$store.dispatch('login', {
                email:this.email,
                password:this.password,
            });
        },
        cancelClick() {
            this.$children[0].close();
        }
    },
    computed: {
        ...mapGetters([
            'hash',
            'user',
            'loginError',
            'content',
        ]),
        page() {
            return this.content('/pages/login');
        },
    },
};

</script>

<style lang="scss">

@import "./colors.scss";

.Login {
}

</style>

