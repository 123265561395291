<template>
	<div class="PageOrganisations PageBasic boxed padded" v-if="page">
        <speech :title="page.title" :body="page.body" />
        <div class="tiles">
            <a v-for="tile in tiles" :key="tile.name"
               :href="hash + '/' + tile.slug"
                class="tile">
                <div class="image" :style="tile.imageStyle" />
                <div class="title">{{tile.name}}</div>
            </a>
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import Speech from './Speech';

function tile(slug, name) {
    const imageStyle = {
        backgroundImage: 'url('+require('./assets/tegel-'+slug+'.jpg')+')',
    };
    return {
        slug,
        name,
        imageStyle,
    };
}

export default {
    components: {
        Speech,
    },
    computed: {
        page() {
            return this.content('/pages/organisations');
        },
        ...mapGetters([
            'content',
            'hash',
        ]),
        tiles() {
            return [
                tile('over', this.page.buttonAbout),
                tile('pro', this.page.buttonPro),
                tile('extra', this.page.buttonPrograms),
            ];
        },
    },
}

</script>
<style lang="scss">

@import "colors.scss";
@import "./variables.scss";

.PageOrganisations {
    .tiles {
        display: flex;
        justify-content: flex-start;
        margin: 40px 0;
    }
    .tile {
        width: 200px;
        margin: 0 20px;
        text-decoration: none;
        .image {
            height: 112px;
            background-size: cover;
        }
        .title {
            padding: 4px;
            height: 60px;
            background: $lighterpurple;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-size: 16px;
            font-weight: bold;
        }
    }

    @media screen and (max-width:$phone) {
        .tiles {
            display: grid;
            gap: 40px;
            justify-content: center;
            align-items: center;
        }
    }

}


</style>
