<template>
	<div class="Bottom">
		<div class="boxed padded">
			<div class="regular">
                <div>
                <span>De WERK-portal.nl is een product van</span>
                <span>
                    <a href="https://www.sbcm.nl/over-ons" target="_blank" aria-label="www.sbcm.nl">
                        <img src="./assets/sbcm-logo.svg" height="28" alt="Het logo van SBCM" />
                    </a>
                </span>
                </div>
                <a href="#/privacy" class="privacy" aria-label="Ga naar de Privacy- en cookieverklaring">Privacy- en cookieverklaring</a>
                <template v-if="phoneView === false"> | </template>
                <a href="#/toegankelijkheidsverklaring" class="accessibility" aria-label="Ga naar de Toegankelijkheidsverklaring">Toegankelijkheidsverklaring</a>
                <template v-if="phoneView === false"> | </template>
                <a href="#/sitemap" class="sitemap" aria-label="Ga naar de Sitemap">Sitemap</a>
			</div>
			<div class="social">
				Volg ons!
				<a v-for="link in socials"
					:key="link.name"
					:target="link.target"
                    :aria-label="`Ga naar de ${link.label}-pagina van WERK-portal.nl`"
					:href="link.url">
                    <icon :name="'brands/'+link.name" scale="2" :aria-label="`Het logo van ${link.label}`" />
				</a>
			</div>
		</div>
	</div>
</template>
<script>

import { mapGetters } from 'vuex';

import 'vue-awesome/icons/brands/facebook-square';
import 'vue-awesome/icons/brands/linkedin';
import 'vue-awesome/icons/brands/youtube-square';


class Link {
	constructor(label, name, url) {
		this.label = label;
		this.name = name;
		this.url = url;
		this.target = url.charAt(0) === '#' ? '_self' : '_blank';
	}
}

export default {
    computed: {
        ...mapGetters([
            'phoneView',
        ]),
    },
	data() { return {
		socials: [
			new Link('Facebook', 'facebook-square', 'https://www.facebook.com/Sterkaanhetwerk'),
			new Link('LinkedIn', 'linkedin', 'https://www.linkedin.com/groups/8493934'),
			new Link('Youtube', 'youtube-square', 'https://www.youtube.com/user/swportal'),
		],
		facebookLink:'',
	}},
}

</script>
<style lang="scss">

@import "colors.scss";
@import "variables.scss";

.Bottom {
	height: 122px;
	background: url('./assets/bg_footer.png');
	.padded {
		padding: 34px 0;
	}

    svg, img {
        vertical-align: middle;
        margin: 0 10px;
    }

	.regular {
		column-count: 1;
		max-width: 800px;
        color: white;
        a {
            color: white;
        }
		&>a {
		}
	}
	.social {
		color: white;
		position: absolute;
		right: 88px;
		top: 48px;
		a {
			color: white;
			text-decoration: none;
		}
	}
	.fa-icon { 
		vertical-align: middle;
		margin-left: 10px;
	}

    @media (max-width: $phone) {
        & {
            padding-top: 20px;
            box-sizing: border-box;
            height: 238px;
        }
        span {
            padding: 8px 0;
            display: block;
            text-align: center;
        }
        span:first-child {
            padding-bottom: 4px;
        }
        .privacy, .accessibility, .sitemap {
            padding: 2px 0;
            display: block;
            text-align: center;
        }
        .sitemap {
            padding-bottom: 8px;
        }
        .social {
            position: normal;
            right: inherit;
            top: inherit;
            text-align: center;
            width: 100%;
        }
    }
}

</style>
