<template>
    <popover class="InstructionVideo" v-if="page">
        <speech v-if="page"
            :title="page.title"
            :body="page.body"
            />
        <div class="embed-container">
            <iframe src="https://player.vimeo.com/video/340201557?autoplay=1&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
    </popover>
</template>
<script>

import { mapGetters } from 'vuex';
import Popover from './Popover';
import Speech from './Speech'

export default {
    components: {
        Popover,
        Speech,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
        ...mapGetters([
            'content',
        ]),
        page() {
            return this.content('/pages/hoe-werkt-het');
        },
    },
};

</script>

<style lang="scss">

@import "./colors.scss";

.InstructionVideo {
    &.Popover .front {
        width: auto;
        max-width: 800px;
    }
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
    }

    .embed-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

</style>

