import Vue from "vue";
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";


// @ugly No such possibility in all standalone apps providing this in a generic way.
// Abort due to not running on production?
if(window.location.hostname.includes('werk-portal.nl')) {
	Sentry.init({
		Vue,
		dsn: "https://173bbad507b44c648c2812109ce8eb66@o88527.ingest.sentry.io/6315707",
		integrations: [
			new BrowserTracing({
				//routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ["localhost", "werk-portal.nl", /^\//],
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}