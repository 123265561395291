<template>
    <div class="PageContact PageBasic" v-if="page">
        <div v-if="!complete" class="boxed padded">
            <speech :title="page.title" :body="page.body" />
            <form @submit.prevent="submit">
                <div v-for="field in fields">
                    <label :for="field.name">{{field.label}}:</label>
                    <input :id="field.name" v-if="!field.text" v-model="input[field.name]" />
                    <textarea :id="field.name" v-model="input[field.name]" v-else></textarea>
                </div>
                <div v-if="error" class="error">{{error}}</div>

                <div class="pad-left">
                    <input class="button" type="submit" value="verzenden" />
                </div>
            </form>
        </div>
        <div v-else class="boxed padded">
            <h1>Bedankt!</h1>
            <p>Je gegevens zijn goed ontvangen, we nemen zo spoedig mogelijk contact met je op.</p>
        </div>
    </div>
</template>
<script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import Speech from './Speech';
import axios from 'axios';
import translateError from './translate-error';

const field = (label,name,text=false) => ({ label, name, text });

export default {
    components: {
        Speech,
    },

    created() {
        this.fields.forEach( f => Vue.set(this.input,f.key,null) );
    },

    data() {
        return {
            input: {
            },
            error: null,
            complete: false,
        };
    },
    methods: {
        submit() {
            const { input, fields }  = this;
            try {
                this.checkInput(input, fields);
                axios.post('/contact', input).then( (res) => {
                    this.complete = true;
                }).catch( (e) => {
                    this.error = translateError(e.response.data);
                });
            } catch(e) {
                this.error = e.message;
                s.commit('setContactError', e.message);
            }
        },
        checkInput(input, fields) {
            fields.forEach( f => {
                if(!input[f.name]) {
                    throw Error(f.label + ' is een verplicht veld.');
                }
            });
        },
    },
    computed: {
        // ...mapGetters([
        //     'contactError',
        //     'contacted',
        // ]),
        fields() {
            return [
                field('Naam', 'name'),
                field('E-mail', 'email'),
                field('Onderwerp', 'subject'),
                field('Vraag', 'body',true),
            ];
        },
        page() {
            return this.content('/pages/contact');
        },
        ...mapGetters([
            'content',
        ]),
    }
};

</script>
<style lang="scss">

.PageContact {
    form>div {
        margin-bottom: 4px;
    }

    label {
        display: inline-block;
        width: 160px;
        vertical-align: top;
    }

    input:not([type=checkbox]), textarea {
        width: 300px;
        border: 1px solid #ddd;
        padding: 8px;
        box-sizing: border-box;
    }
    textarea {
        height: 160px;
    }

    .button {
        display: block;
        margin-top: 10px;
    }

    .pad-left {
        padding-left: 160px;
    }
    .error {
        width: 440px;

    }

}

</style>
