<template>
    <div class="DocumentList">
        <slot />
        <search-input v-model="needle" />
        <div v-for="cat in filtered" :key="cat.name">
            <h2 :aria-label="cat.name">{{cat.name}}</h2>
            <a v-for="doc in cat.docs" :key="doc.name" class="doc" :href="downloadLink(doc)" target="_blank" aria-label="download">
                <icon :name="iconName(doc)" scale="2" />
                {{doc.name}}
                <span class="button">download</span>
            </a>
        </div>
    </div>
</template>
<script>


import SearchInput from './SearchInput';

import 'vue-awesome/icons/file-pdf';
import 'vue-awesome/icons/file-image';

export default {

    props: [
        'categories',
    ],
    data() {
        return {
            needle: null,
        };
    },

    methods: {
        iconName(doc) {
            if(doc.file.indexOf('.pdf') !== -1) {
                return 'file-pdf';
            }
            return 'file-image';
        },
        downloadLink(doc) {
            const ext = doc.file.substr(doc.file.length-3);
            return '/api/docs/download'+doc.file+'?name='+doc.name+'.'+ext;
        },
    },
    components: {
        SearchInput,
    },
    computed: {
        filtered() {
			if(!this.needle || !this.categories) {
				return this.categories;
			}
			const letters = this.needle.toLowerCase().split('');
			const resultsCat = {
				name: 'Zoekresultaten',
				docs: [],
			};
			this.categories.forEach( (cat) => {
				cat.docs.forEach( (doc)=> {
					const name = doc.name.toLowerCase();
					let prev = -1;
					for(let i=0; i<letters.length; i++) {
						let idx = name.indexOf(letters[i], prev+1);
						if(idx > prev) {
							prev = idx;
						} else {
							return;
						}
					}
					resultsCat.docs.push( doc );
				});
			});
			return [ resultsCat ];
        },
    },
};

</script>
<style lang="scss">

@import "colors.scss";

.DocumentList {
    h2 {
        margin: 20px 0;
    }
    .SearchInput {
        margin-left: auto;
    }
    .doc {
        display: flex;
        align-items: center;
        text-decoration: none;
        border-bottom: 2px dashed $lightgray;
        .fa-icon {
            color: $green;
            vertical-align: middle;
            margin: 10px;
        }
        .button {
            margin-left: auto;
        }
    }
}

</style>

